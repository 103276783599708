import React, { useEffect } from "react";
import {
    Typography,
    Box,
    Grid
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";

const PrintPackingSlip = () => {
    const [t] = useTranslation();
    // const [skeleton, setSkeleton] = useState(true);

    // const location = useLocation();
    // const  orderId = location?.state?.orderId ?? null;
    // const  orderData = location?.state?.orderData ?? null;
    const orderId = useAppSelector((state) => state.common.isOrderIdStored) ?? null;
    const orderData = useAppSelector((state) => state.common.isOrderDataStored) ?? null;

    useEffect(() => {
        hideComponent();
        setTimeout (()=>{
            window.print();
            showComponent();
            window.close();
        },500);
    }, []);

    const hideComponent=()=>{
        // const footer = document.getElementsByClassName("footer")[0].setAttribute("style","display:none;");
        // const header = document.getElementsByClassName("cz_header")[0].setAttribute("style","display:none;");
    };

    const showComponent = () => {
        // const footer = document.getElementsByClassName("footer")[0].removeAttribute("style");
        // const header = document.getElementsByClassName("cz_header")[0].removeAttribute("style");
    };

    const storename = useAppSelector((state) => state?.common?.loggedInStoreName) ?? "";
    
    return (           
        <Grid container id="packing_slip">
            {
                orderId?.map((order_id: string , orderIndex: number) => (
                    <Grid item lg={8} md={12} sm={12} xs={12} key={orderIndex} sx={{minHeight:100+Math.floor((orderData?.filter((e:any)=>e.orderId == order_id)[0]?.products.length+1)/4)*100+"vh"}}>
                        {
                            orderData?.filter((orderItem:any)=>orderItem.orderId==order_id).map((product:any, index: number)=>{
                                const products = product?.products; 
                                return(
                                    <Box key={index}>
                                        {/* {
                                            index == 0 ? ( */}
                                        <>
                                            <Box className="packing_slip" key={index}>
                                                <Box className="packing_slip_head">
                                                    <Typography component="h6">{t("oms.ship_to")}:</Typography>
                                                    <Typography component="h4" className="fw"> {product.ordererPostalCode}</Typography>
                                                    <Typography component="h4" className="fw">{product.ordererAddress1}</Typography>
                                                    <Typography component="h4" className="fw">{product.ordererAddress2}</Typography>
                                                    <Typography component="h4" className="fw">{product.ordererAddress3}</Typography>
                                                    <Typography component="h4" className="fw">{product.ordererPrefecture}</Typography>
                                                </Box>
                                                <Box>
                                                    {/* <Typography component="h4" className="fw">{t("oms.tokuda")}</Typography> */}
                                                    <Typography component="h3" className="border_dashed fw">{t("oms.order_number")}: <Typography component="span">{product.orderId}</Typography></Typography>
                                                    <Typography>{t("oms.it")} {storename} {t("oms.it_shop")}</Typography>
                                                </Box>
    
                                                <Box className="shoping_information">
                                                    <Box>
                                                        <Typography className="fw">{t("oms.shipping_to")}</Typography>
                                                        <Typography component="h4" className="fw"> {product.ordererPostalCode}</Typography>
                                                        <Typography component="h4" className="fw">{product.ordererAddress1}</Typography>
                                                        <Typography component="h4" className="fw">{product.ordererAddress2}</Typography>
                                                        <Typography component="h4" className="fw">{product.ordererAddress3}</Typography>
                                                        <Typography component="h4" className="fw">{product.ordererPrefecture}</Typography>
                                                       
                                                    </Box>
    
                                                    <Box>
                                                        <Typography className="fw">{t("oms.order_date")} </Typography>
                                                        {/* <Typography>{t("oms.shipping_methods")} </Typography> */}
                                                        <Typography>{t("oms.buyer_name")} </Typography>
                                                        <Typography>{t("oms.seller_name")} </Typography>
                                                    </Box>
    
                                                    <Box>
                                                        <Typography>{product.orderDate} </Typography>
                                                        {/* <Typography>{product.shippingCompanyMethod}</Typography> */}
                                                        <Typography>{product.ordererKanaSei} {product.oredereKanaMei}</Typography>
                                                        <Typography>{product.sellerName}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Grid container className="product_detail-head">
                                                    <Grid item lg={2} md={2} sm={2} xs={2}>
                                                        <Typography className="fw">{t("oms.quantity")}</Typography>  
                                                    </Grid>                                  
                                                    <Grid item lg={4} md={4} sm={4} xs={4}>
                                                        <Typography className="fw">{t("oms.product_details")}</Typography>
                                                    </Grid>
                                                    <Grid item lg={2} md={2} sm={2} xs={2}>
                                                        <Typography  className="fw">{t("oms.unit_price")}</Typography>
                                                    </Grid>
                                                    <Grid item lg={4} md={4} sm={4} xs={4}>
                                                        <Typography  className="fw">{t("oms.total_orders")}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <>
                                                    {products?.map((product:any, productIndex:any)=>{ 
                                                       
                                                        if(productIndex==2){
                                                            return (
                                                                <Box key={productIndex} className="page_break"></Box>
                                                            );
                                                        } else if((productIndex+2) % 4 == 0 && productIndex != 0 ){
                                                            return (
                                                                <Box key={productIndex} className="page_break"></Box>
                                                            );
                                                        }
                                                        return(
                                                            <>
                                                                <Box className="product_detail" key={
                                                                    productIndex
                                                                }>
                                                                    {/* Pay slip Head  */}
                                                                    {/* Pay slip Data body  */}

                                                                    <Grid container className={productIndex % 2 == 0 && productIndex!=0 ? ("product_detail-body border-top ") : "product_detail-body"}>
                                                                        <Grid item lg={2} md={2} sm={2} xs={2}>
                                                                            <Box>
                                                                                <Typography>{product.salesVolume} / {product.salesVolume} </Typography>
                                                                            </Box>
                                                                        </Grid>
                                                
                                                                        <Grid item lg={4} md={4} sm={4} xs={4} 
                                                                        >
                                                    
                                                                            <Box >
                                                                                <Typography>{product.productName}</Typography>
                                                                                {/* <Typography className="fw">Sheet Refill 3m Including sales tax</Typography> */}
                                                                                <Typography className="fw">{t("oms.cpid")}: <Typography component="span">{product.cainzProductCode}</Typography></Typography>
                                                                                {/* <Typography className="fw">{t("oms.product_id")}: <Typography component="span">{product.productId}</Typography></Typography> */}
                                                                                {/* <Typography className="fw">{t("oms.condition")}: <Typography component="span">New</Typography></Typography> */}
                                                                                {/* <Typography className="fw">Order Product ID: <Typography component="span">00334444547358</Typography></Typography> */}
                                                                            </Box>
                                                    
                                                                        </Grid>
                                                                        <Grid item lg={2} md={2} sm={2} xs={2}>
                                                    
                                                                            <Box  >
                                                                                <Typography>¥ {product?.productPriceIncludingTax}</Typography>
                                                                            </Box>
                                                        
                                                                        </Grid>
                                                                        <Grid item lg={4} md={4} sm={4} xs={4}>
                                                        
                                                                            <Box className="total_order" >
                                                                                <Typography component="h6">{t("oms.include_sales_tax")}</Typography>
                                                                                <Typography className="fw border_bottom">{t("oms.product_subtotals")} <Typography component="span">¥{product.productPriceIncludingTaxSubtotal}</Typography></Typography>
                                                                                <Typography className="fw border_bottom">{t("oms.total_by_product")} <Typography component="span">¥{product.productPriceIncludingTaxSubtotal}</Typography></Typography>
                                                                                {/* <Typography className="fw border_bottom">{t("oms.cainz_points_awarded")} <Typography component="span">0 points</Typography></Typography> */}
                                                                            </Box>
                                                    
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            </>
                                                        );
                                                    })
                                                    }
                                                </>
                                            </Box>
                                            <>
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Box className="float_right" sx={{my:2}}>
                                                        <Typography className="fw">{t("oms.total_price")}: <Typography component="span">¥ {product?.totalOrderAmount}</Typography></Typography>
                                                        <Typography>{t("oms.total_points")}: <Typography component="span">{product?.totalPointAwarded}</Typography></Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Box sx={{my:6}}>
                                                        <Typography>  
                                                            {t("oms.print_packing_slip_defination")}
                                                        </Typography>                                  
                                                    </Box>
                                                    <Box className="border_dashed table_bottom">  </Box>
                                                </Grid>
                                        
                                            </>
                                        </>
                                          
                                        {/* ) : (index == 2 ? (
                                                            
                                                 <>
                                                     <Box  className="page_break"></Box>
                                                 </>
                                             ) :(
                                                 (index+2) % 4 == 0 && index != 0 ? (
                                                     <>
                                                         <Box className="page_break"></Box>
                                                     </>
                                                 ) : null
                                             )
                                             )
                                         } */}
                                                    
                                       

                                        {/* // {
                                        //     index == orderData?.filter((e:any)=>e.orderId == order_id).length-1 ? (
                                               
                                        //     ) : null
                                        // } */}

                                    </Box>
                                );
                            }
                                
                            ) 
                        }
                           
                    </Grid> 
                ))
            }
                        
        </Grid>
                    
    );
};

export default PrintPackingSlip;



