import React, { useEffect, useState } from "react";
import {
    Button,
    Container,
    Typography,
    Box,
    Grid,
    Divider,
    Checkbox,
    TableContainer,
    CardContent,
    Card,
    Paper,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    Table,
    capitalize,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    InputAdornment,
    IconButton,
    TextField,
    SelectChangeEvent
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import CONSTANTS, { LIST_LIMIT } from "../../../constants/constants";
import OmsManageOrderService from "../service/OmsManageOrderService";
import { useTranslation } from "react-i18next";
import Pagination from "../../common/Pagination";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../constants/routes";
import Skeleton from "../../common/Skeleton";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import Utility from "../../../utils/Utility";
import { commonActions } from "../../../redux/features/common/commonSlice";
import lazyTypingUtils from "../../../utils/lazyTypingUtils";
import CommonButton from "../../common/Button";

const RefundedOrders = (props: any) => {
    const { showSnackbar, handleLoader } = props;
    const refundOrderFilter = useAppSelector((state:any) => state?.common?.refundOrderFilter);
    const omsCurrentPage = useAppSelector((state:any) => state.common.refundedOrders);
    const perPageLimit = useAppSelector((state) => state.common.perPageLimit);

    const defaultLimit=LIST_LIMIT.LIMIT_15;
    const firstPage = CONSTANTS.FIRST_PAGE;
    const dispatch = useAppDispatch();
    const [t, i18n] = useTranslation();
    const [skeleton, setSkeleton] = useState(true);
    const [currentPage, setCurrentPage] = useState<any>(omsCurrentPage?.currentPage ?? 1);
    const [totalCount, setTotalCount] = useState<any>(0);
    const [totalPages, setTotalPages] = useState<any>(0);
    // const [limit, setLimit] = useState(omsCurrentPage?.limit);
    const [limit, setLimit] = useState(!perPageLimit ? defaultLimit : perPageLimit );
    const [refundedOrdersData, setrefundedOrdersData] = useState<any>([]);
    const [search, setSearch] = useState<string>(refundOrderFilter?.search ? refundOrderFilter?.search : "");
    const [filterChange, setFilterChange] = useState(false);
    
    
    // const [dateRang, setDateRang] = useState<any>(7);
    // const [orderDate, setOrderDate] = useState("AESC");

    const orderIdCompare: any[] = [];

    // const [checked, setChecked] = useState(new Array(limit).fill(false));

    // const handleCheckAllChange = (e: any) => {
    //     e.target.checked == true
    //         ? setChecked(new Array(i).fill(true))
    //         : setChecked(new Array(i).fill(false));
    // };

    // const handleCheckboxChange = (index: any) => (event: any) => {
    //     const newChecked = [...checked];
    //     newChecked[index] = event.target.checked;
    //     setChecked(newChecked);
    // };
    
    const navigate = useNavigate();

    // useEffect(() => {
    //     omsManagetData(firstPage,limit);
    //     totalPages != 0 ? setrefundedOrdersData(currentPage) : null;
    // }, []);

    useEffect(() => {
        const handlePageRefresh = () => {
            dispatch(commonActions.OMS_ORDER_FILTER_REMEMBER({}));
            dispatch(commonActions.REFUNDED_ORDER_FILTER_REMEMBER({}));
            dispatch(commonActions.IS_UNSHIPPED({currentPage: 1, limit: 15}));
            dispatch(commonActions.IS_SHIPPED({currentPage: 1, limit: 15}));
            dispatch(commonActions.IS_PREPARING_SHIPPMENT({currentPage: 1, limit: 15}));
            dispatch(commonActions.IS_CANCEL({currentPage: 1, limit: 15}));
            dispatch(commonActions.IS_REFUND({currentPage: 1, limit: 15}));
            dispatch(commonActions.IS_DOWNLOAD({ currentPage: 1, limit: 15 }));
        };
        window.addEventListener("beforeunload", handlePageRefresh);
        return () => {
            window.removeEventListener("beforeunload", handlePageRefresh);
        };
    }, []);


    const omsManagetData = (currentPage: number , limit: number) => {
        setFilterChange(false);
        setSkeleton(true);
        const payload = {};
        const isHitDummyURL = false;
        new OmsManageOrderService(payload, isHitDummyURL)
            .omsRefundOrderList(currentPage,limit,search)
            .then((res) => {
                if(res?.pagination?.totalCount>=0){                   
                    setSkeleton(false);
                }
                setrefundedOrdersData(res?.refundedOrderData);
                setCurrentPage(currentPage);
                setTotalCount(res?.pagination?.totalCount);
                setTotalPages(res?.pagination?.totalPages);
                setLimit(limit);
                dispatch(commonActions.IS_REFUND({currentPage: currentPage,limit: limit}));
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
            });
    };
    
    const handleSearchChange = (e: any) => {
        setFilterChange(true);
        const { value } = e.target;
        setSearch(value);
    };
    // const handleSelectRange = (event: SelectChangeEvent) => {
    //     setDateRang(event.target.value);
    // };
    // const handleSelectOrderDate = (event: SelectChangeEvent) => {
    //     setOrderDate(event.target.value);
    // };
    useEffect(() => {
        omsManagetData(currentPage, limit);
    }, []);

    const onsearchApicall = () =>{
        const data={
            search
        };
        dispatch(commonActions.REFUNDED_ORDER_FILTER_REMEMBER(data));
        if(filterChange || search !==""){
            omsManagetData(firstPage,limit);
        }
    };

    const propItem = [
        onsearchApicall,search
    ];
    lazyTypingUtils(propItem);


    const handleSumbitId = (id: number) => {
        navigate(`${ROUTES.OMS_CONFIRM_SHIPMENT}/${id}`);
    };
    const handleRefund = (id: number) => {
        navigate(`${ROUTES.REFUND_ORDER}/${id}`);
    };
    const resetAllFilter = () =>{
        setFilterChange(true);
        setSearch("");      
    };

    // let i = 0;
    localStorage.setItem("panel","2,1,3,4");


    return (
        <>
            <Container>
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        {/* {refundedOrdersData?.length == 0 ? (
                            <Skeleton
                                varient="rectangular"
                                width={0}
                                height={484}
                                count="20"
                            />
                        ) : (
                            <OmsRefundedOrderFilter />
                        )} */}
                        <Box className="oms_unshipped_filter">
                            <Grid container spacing={2}>
                                <Grid item lg={7} md={6} sm={4} xs={4}>
                                    <Box sx={{display:"inline-flex" ,mt:1 }}>
                                        <Typography className="oms-shipped-text" sx={{display:"inline-flex"}} ><Box component="span" className="oms_text_margin">{totalCount}</Box> {t("oms.no_of_refund_order")}
                                        </Typography>                                   
                                    </Box>
                                </Grid>
                                <Grid item xs={4} sm={5} md={4} lg={3} sx={{ py: 1 }}>
                                    <TextField
                                        // id="input-with-icon-textfield"
                                        label={t("oms.search_by_order_id")}
                                        onChange={handleSearchChange}
                                        // onKeyUp={handleKeyPress}
                                        value={search}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={4} sm={3} md={2} lg={2}  className="reset_btn">
                                    <Button
                                        variant="contained"                           
                                        sx={{textTransform: "capitalize"}}
                                        onClick={resetAllFilter}
                                        className="oms_submit-btn"
                                    >
                                        {t("pr.reset")}
                                    </Button>
                                </Grid> 
                                {/* <Grid item lg={2} md={5} sm={5} xs={12} > */}
                                {/* <FormControl size="small" fullWidth>
                                        <InputLabel id="demo-simple-select-helper-label" >
                                            {t("oms.date_range")}                                       
                                        </InputLabel>
                                        <Select fullWidth
                                            className="oms-tab-filters"
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={dateRang}
                                            label={t("oms.date_range")}
                                            onChange={handleSelectRange}
                                        >
                                            <MenuItem value={7}>{t("oms.last_7_days")}</MenuItem>
                                            <MenuItem value={30}>{t("oms.last_30_days")}</MenuItem>
                                            <MenuItem value={90}>{t("oms.Last_90_days")}</MenuItem>
                                            <MenuItem value={365}>{t("oms.last_year")}</MenuItem>
                                        
                                        </Select>
                                    </FormControl> */}
                                {/* </Grid>               */}
                                {/* <Grid item lg={2} md={5} sm={5} xs={12}> */}
                                {/* <Box>
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="demo-simple-select-helper-label">
                                                {t("oms.order_date")}
                                            </InputLabel>
                                            <Select fullWidth
                                                className="oms-tab-filters"
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={orderDate}
                                                label= {t("oms.order_date")}
                                                onChange={handleSelectOrderDate}
                                            >
                                                <MenuItem value="AESC">{t("oms.ascending")}</MenuItem>
                                                <MenuItem value="DESC">{t("oms.descending")}</MenuItem>
                                            
                                            </Select>
                                        </FormControl>
                                    </Box> */}
                                {/* </Grid> */}
            
                                {/* <Grid item lg={1} md={2} sm={2} xs={12}> */}
                                {/* <Box sx={{mt:1}}>
                                        <SettingsIcon />
                                        <RefreshIcon />
                                    </Box> */}
                                {/* </refundedOrdersData> */}
                           
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <Container className="oms_table">
                <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        {skeleton ? (
                            <Skeleton
                                varient="rectangular"
                                width={0}
                                height={253}
                                count="10"
                            />
                        ) : (
                            <TableContainer component={Paper} className="refunded_table">              
                                            
                                <Table aria-label="simple table">
                                    <TableHead className="oms_head">
                                        <TableRow>
                                            <TableCell>
                                                <Box sx={{py:1}}>
                                                    
                                                    {t("oms.order_date")}
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>{t("oms.order_details")}</Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>{t("oms.product_information")} </Box>
                                            </TableCell>
                                            
                                            <TableCell className="order_status">
                                                <Box>{t("oms.order_status")}</Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>{t("oms.action")}</Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    
                                    <TableBody>
                                        {/* <TableRow> 
                                            <TableCell colSpan={6} >
                                                <Typography sx={{pl:3}} className="CZ-Cancellation-Error">
                                                    <ReportProblemIcon color="secondary" sx={{mr:2}}/>
                                                    {t("oms.the_buyer_has_requested")}
                                                </Typography>
                                            </TableCell>
                                        </TableRow> */}
                                        {refundedOrdersData?.map((item: any, idIndex: number) => {
                                            const products = item?.products;                                              
                                            return (
                                                <TableRow key={idIndex}>
                                                    {/* {!orderIdCompare.includes(item.orderId) ? ( */}
                                                    <TableCell>
                                                        <Typography
                                                            className={"order_date_checkbox " + idIndex}
                                                        >
                                                            {/* <Checkbox
                                                                checked={checked[i]}
                                                                onChange={handleCheckboxChange(i++)}
                                                            /> */}
                                                            <Box sx={{display: "inline-grid"}}>
                                                                <Typography>
                                                                    {t("oms.order_date")} :
                                                                </Typography>
                                                                <Typography>
                                                                    {item.orderDate}
                                                                </Typography>
                                                                <Typography>
                                                                    {t("oms.order_confirmation_date")} :
                                                                </Typography>
                                                                <Typography>
                                                                    {item.orderConfirmationDate}
                                                                </Typography>
                                                            </Box>
                                                        </Typography>
                                                    </TableCell>
                                                    {/* ) : null} */}
                                                    {/* {refundedOrdersData
                                                            ?.filter(
                                                                (e: any) =>
                                                                    e.orderId == item.orderId &&
                                  !orderIdCompare.includes(e.orderId)
                                                            )
                                                            ?.map((store: any, storeIndex: number) => {
                                                                orderIdCompare.push(item.orderId);
                                                                return storeIndex == 0 ? ( */}
                                                    <TableCell key={idIndex} colSpan={5}>
                                                        <Table>
                                                            <TableBody>
                                                                <TableRow className="CEC-border-none">
                                                                    <TableCell>
                                                                        <Typography>
                                                                            {t("oms.order_id")} : 
                                                                        </Typography>
                                                                        <Typography className="text_wrap_data">
                                                                            <Link to={`${ROUTES.OMS_CONFIRM_SHIPMENT}/${item?.orderId}`} className="Order_id_link">
                                                                                {item?.orderId}
                                                                            </Link>
                                                                        </Typography>
                                                                       
                                                                    </TableCell>
                                                                    <TableCell>
                                                                   
                                                                   
                                                                        <Table>
                                                                            <TableBody>
                                                                                {products?.map((product:any,productIndex:any)=>
                                                                                                             
                                                                                    <TableRow
                                                                                        key={
                                                                                            productIndex + 30
                                                                                        }
                                                                                        className="table_border"
                                                                                    >
                                                                                        <TableCell className="table_cell_padding">
                                                                                            <img
                                                                                                src={
                                                                                                    product?.image
                                                                                                }
                                                                                                alt=""
                                                                                            />
                                                                                            <Typography className="text_wrap_data">
                                                                                                {
                                                                                                    product?.productName
                                                                                                }
                                                                                            </Typography>

                                                                                            <Typography className="text_wrap_data">
                                                                                                {t("oms.cpid")} : { product?.cainzProductCode}
                                                                                            </Typography>
                                                                                                                        
                                                                                            <Typography className="text_wrap_data">
                                                                                                {t("oms.quantity")} : {product?.salesVolume}
                                                                                                                                
                                                                                            </Typography  >
                                                                                            {t("oms.price_subtotal")} : {new Utility().numberWithCommas(product?.productPriceSubtotal)}          
                                                                                            <Typography className="text_wrap_data">
                                                                                                {t("oms.refunded_quantity")} : {product?.productReturnQuantity}
                                                                                            </Typography>
                                                                                            <Typography className="text_wrap_data">
                                                                                                {t("oms.refund_subtotal")} : {new Utility().numberWithCommas(product?.productRefundSubtotal)}
                                                                                            </Typography>
                                                                                            {
                                                                                                productIndex+1 == products?.length ?
                                                                                                    "":
                                                                                                    <Divider sx={{mt:3}}/>
                                                                                            }
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            <Box className="table_button">
                                                                                                <Button
                                                                                                    className="unshipped order_status"
                                                                                                    variant="contained" 
                                                                                                    color="error"
                                                                                                    sx={{mb:1}}
                                                                                                >
                                                                                                    {t("oms.refunded_orders")}
                                                                                               
                                                                                                </Button>
                                                                                         
                                                                                                {/* <Typography className="CZ-Cancellation-Error" style={{fontWeight: "bold",marginTop:"10px"}}  >
                                                                                                <ReportProblemIcon color="secondary" />
                                                                                                {t("oms.buyer_cancellation")}</Typography> */}
                                                                                                {/* <Typography mx="1"> {t("oms.cancellation_reason")}</Typography> */}
                                                                                                <Typography mx="1" className="text_wrap_data"> {product?.refundReasonText}</Typography>
                                                                                                {/* <Typography mx="1" className="text_wrap_data"> {product?.refundReason == 1 ? t("oms.unable_to_ship_seller_responsibility") : product?.refundReason == 2 ? t("oms.wrong_product_shipped_seller_responsibility") :product?.refundReason == 3 ? t("oms.delivery_delay_seller_responsibility") : product?.refundReason == 4 ? t("oms.other_reasons_seller_responsibility") : product?.refundReason == 5 ? t("oms.refusal_of_receipt_customer_responsibility") :product?.refundReason == 6 ? t("oms.wrong_product_ordered_customer_responsibility") : t("oms.other_reasons_customer_responsibility")}</Typography> */}
                                                                                                <Typography>{t("oms.refund_app_date")} : <Box>{item?.refundApplicationDate}</Box></Typography>
                                                                                            </Box>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                )}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableCell>

                                                                     

                                                                    <TableCell>
                                                                        <Box className="table_button ">
                                                                            <CommonButton route={window.location.pathname} functionToExecute={() => handleSumbitId(item?.orderId)} className="unshipped textform_capitalize margin_bottom" btnName={t("oms.edit_shipment")} disableCondition={false}/>
                                                                            {/* <Button
                                                                                sx={{mb:1, textTransform:"capitalize"}}
                                                                                variant="contained"
                                                                                className="unshipped" 
                                                                                onClick={() =>
                                                                                    handleSumbitId(item?.orderId)
                                                                                }
                                                                            > {t("oms.edit_shipment")} </Button> */}
                                                                            <CommonButton route={window.location.pathname} functionToExecute={() => handleRefund(item?.orderId)} className="unshipped background_color_red" btnName={t("oms.refund_order")} disableCondition={false}/>                                                               
                                                                            {/* <Button   className="unshipped "
                                                                                variant="contained" 
                                                                                color="error"
                                                                                onClick={() =>handleRefund(item?.orderId)}
                                                                            >  
                                                                                {t("oms.refund_order")} 
                                                                            </Button> */}
                                                                        </Box>                                                                                       
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableCell>                                                  
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                        <Typography align="center" variant="h6">
                            {refundedOrdersData?.length == 0 ? t("oms.no_order_found") : ""}
                        </Typography>
                    </Grid>
                </Grid>
                <Pagination
                    totalCount={totalCount}
                    currentPage={currentPage}
                    pageChange={omsManagetData}
                    totalPage={totalPages}
                    rowsPerPage={limit}
                    rowsPerPageChange={setLimit}
                />
            </Container>
        </>
    );
};

export default RefundedOrders;
