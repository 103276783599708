import { Container, Typography } from "@mui/material";
import React, { useState } from "react";
import HeaderTitle from "../../common/HeaderTitle";
import { useTranslation } from "react-i18next";
import ProfileService from "../services/ProfileService";
import RichTextEditor from "../../common/RichTextEditor";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { userActions } from "../../../redux/features/user/userSlice";
import { SnackbarAlert } from "../../common/SnackbarAlert";

const PrivacyPolicy = (props: any) => {

    const { showSnackbar, handleLoader } = props;

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const privacy = useAppSelector((state) => state.user.userDetails?.store?.[0].policy?.privacyPolicy) ?? null;
    const [alertMessage, setAlertMessage] = useState<string>();
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const success = false;

    const savePrivacy = (data: string) => {
        handleLoader(true);
        dispatch(userActions.SAVE_PRIVACY_POLICY(data));
        const payload = { privacyPolicy: data };
        const isHitDummyURL = false;
        new ProfileService(payload, isHitDummyURL)
            .sellerBussinessInfo()
            .then((res) => {
                handleLoader(false);
                showSnackbar(res, true);
            })
            .catch((err) => {
                handleLoader(false);
                showSnackbar(err?.message, false);
            });
    };

    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    };

    return (
        <>
            {/* Page header title */}

            <HeaderTitle title={t("profile.privacy_policy")} />

            {/* Page header title */}

            <Container>
                <Typography variant="h6" sx={{ my: 2 }}>
                    {t("profile.privacy_policy")}
                </Typography>
                <RichTextEditor data={privacy} saveData={savePrivacy} />
                <SnackbarAlert
                    openSnackBar={openSnackBar}
                    message={alertMessage}
                    success={success}
                    handleCloseSnackBar={handleCloseSnackBar}
                />
            </Container>
        </>
    );
};

export default PrivacyPolicy;
