import { Grid, Box, Container, Typography, Button, FormControl, Select, OutlinedInput, MenuItem, Divider, InputLabel, TextField } from "@mui/material";
import {useTranslation} from "react-i18next";
import React, { useEffect, useState } from "react";
import ShipmentDataService from "../service/shipmentDataService";
import CONSTANTS from "../../../constants/constants";
import CommonButton from "../../common/Button";

const ConfirmOrder = (props: any) => {

    const {productSummary, confirmShipmentData, vadFlag, handleFinalShipment, flag, trackingId, carrier, shipDate, handleChanges, setProductQuantityOption, handleChange, quantity, deleteShipment, shipProduct, itemProduct, others, productCount, disableConfirmShipment, isEdit, showSnackbar, handleLoader, confirmShipmentModel, setOpenShipmentModal, setOpenEditShipmentModal, setConfirmShipmentModel, confirmedData, validator, disableEditShipment} = props;

    const [t] = useTranslation();    
    const[trackingRef, setTrackingRef] = useState<any>([]);

    useEffect(() => {
        setTrackingRef([...trackingId]);
    }, [trackingId]);

    // ***** Edit shipment button to send shipment detail for each package  ***** //
    const handleEditShipment = (
        shippedQuantity: any,
        shipmentDate: any,
        carrier: any,
        trackingId: any,
        productIndex: number,
        orderId: any
    ) => {
        const responseArr: any = [];
        Object.keys(shippedQuantity).map(function (key) {
            responseArr.push({
                productCode: key,
                shipmentDate: shipmentDate,
                carrier: carrier,
                shippedQuantity: shippedQuantity[key].toString(),
                trackingId: trackingId,
            });
        });
        const response = {
            orderId: orderId,
            position: productIndex,
            products: responseArr
        };
        console.log(vadFlag);
        if (validator.current.allValid()) {
            handleLoader(true);
            const payload = response;
            const isHitDummyURL = false;
            new ShipmentDataService(payload, isHitDummyURL)
                .editShipmentData()
                .then((res) => {
                    handleLoader(false);
                    showSnackbar(res, true);
                    confirmedData();
                    const shipmentArray = confirmShipmentModel;
                    shipmentArray[productIndex] = true;
                    setConfirmShipmentModel(shipmentArray);
                    setOpenEditShipmentModal(true);
                    setOpenShipmentModal(false);
                })
                .catch((err) => {
                    handleLoader(false);
                    showSnackbar(err?.message, false);
                });
        } else {
            validator.current.showMessages();
        }
    };

    //************Ship Date Dropdown function*****************
    const currentDate = new Date();
    const startDate = confirmShipmentData?.orderDate;
    const dateOptions: any = [];
    let formattedCurrentDate: any;

    const generateDates = () => {
        const date = new Date(startDate.split(" ")[0]);

        while (date <= currentDate) {
            const formattedDate = date.toLocaleDateString("en-ZA", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
            });

            dateOptions.push(formattedDate);
            date.setDate(date.getDate() + 1);
        }

        formattedCurrentDate = currentDate.toLocaleDateString("en-ZA", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        });
    
    };

    generateDates();

    const cancelEditModel = (productIndex: number) => {
        const model = new Array(confirmShipmentModel.length).fill(true);
        model[productIndex] = true;
        setConfirmShipmentModel(model);
        setOpenShipmentModal(false);
    };

    // const checkTrackingId = (indexProduct: number) => {
    //     const trackingIdResult =  trackingId.map((item: any, index: number) => {
    //         if(index == indexProduct){
    //             return false;
    //         }
    //         if(item == trackingId[indexProduct]){
    //             if(carrier[index] == carrier[indexProduct]){
    //                 return true;
    //             }else{
    //                 return false;
    //             }
    //         } else {
    //             return false;
    //         }
    //     });
    //     return trackingIdResult.filter((item: any) => item == true).length > 0 ?  true : false;
    // };

    return (
        <div className="main">
            <Grid key={itemProduct} sx={{paddingTop: "50px"}}>
                <Box className="oms_confirm" id="oms_confirm">
                    <Container>
                        <Grid container spacing={2}>
                            <Grid item lg={11} md={11} sm={11} xs={11}>
                            </Grid>
                            <Grid item lg={1} md={1} sm={1} xs={1}>
                                {
                                    isEdit ? null : (
                                        <CommonButton route={window.location.pathname} functionToExecute={()=>deleteShipment(itemProduct)} className="float-rt" btnName={t("oms.delete")} disableCondition={shipProduct[shipProduct.length - 1] != itemProduct?true:false}/>

                                    // <Button
                                    //     variant="contained"
                                    //     disabled={
                                    //         shipProduct[shipProduct.length - 1] != itemProduct
                                    //             ? true
                                    //             : false
                                    //     }
                                    //     onClick={() => deleteShipment(itemProduct)}
                                    //     sx={{ float: "right" }}
                                    // >
                                    //     {t("oms.delete")}
                                    // </Button>
                                    )
                                }
                            </Grid>
                            {productSummary[productCount]?.products?.map((item: any, indexx: number) => {
                                return (
                                    <Grid
                                        item
                                        lg={4}
                                        md={6}
                                        sm={12}
                                        xs={12}
                                        key={indexx}
                                    >
                                        <Box className="cancel_card">
                                            <Grid sx={{ mt: 1 }}>
                                                <Typography className="text_wrap_data">
                                                    {t("oms.product_name")} : &nbsp;{item?.productName}
                                                </Typography>
                                                <Typography className="text_wrap_data">
                                                    {t("oms.cpid")} : &nbsp;{item?.cainzProductCode}
                                                </Typography>
                                                <Typography className="text_wrap_data">
                                                    {t("oms.sku")} : &nbsp;{item?.salesVolume}
                                                </Typography>
                                                <FormControl fullWidth>
                                                    <Select
                                                        className="cancel_select"
                                                        fullWidth
                                                        size="small"
                                                        id="demo-multiple-name"
                                                        value={
                                                            quantity?.product[itemProduct][
                                                                item?.cainzProductCode
                                                            ]
                                                        }
                                                        onChange={(e) =>
                                                            handleChange(
                                                                e,
                                                                item?.cainzProductCode,
                                                                itemProduct
                                                            )
                                                        }
                                                        input={<OutlinedInput />}
                                                    >
                                                        <MenuItem value={0}>0</MenuItem>
                                                        {setProductQuantityOption(
                                                            item?.cainzProductCode,
                                                            itemProduct
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                );
                            })
                            }
                        </Grid>
                    </Container>
                </Box>
        
                <Grid
                    item
                    lg={12}
                    className="shipment-confirm-detail"
                    sx={{
                        border: "#e4e4e4 2px solid",
                        p: 3,
                        pt: 1,
                        backgroundColor: "#fff",
                        mt: 2,
                    }}
                >
                    <Grid item lg={12} sx={{ mb: 1, mt: 1 }}>
                        <Typography component="h6">
                            {t("oms.confirm_shipment")}
                        </Typography>
                    </Grid>
                    <Divider />
                    <Grid
                        item
                        lg={12}
                        sx={{ display: "flex", my:10, justifyContent: "center" }}
                        className="oms-shipment-tabs"
                    >
                        
                        <Grid sx={{ mr: 2, width: "100%", }}>
                            <FormControl  sx={{ mt: 1, width: "100%" }} >
                                <InputLabel id="demo-multiple-select-label"> 
                                    {t("oms.ship_date")}
                                </InputLabel>
                                <Select
                                    sx={{
                                        height: "auto",
                                        backgroundColor: "#f0f2f2",
                                      
                                    }}
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name={"ship-date" + itemProduct}
                                    value={shipDate[itemProduct] ? shipDate[itemProduct] : formattedCurrentDate}
                                    onChange={(e) => handleChanges(e, itemProduct)}
                                    input={<OutlinedInput label={t("oms.ship_date")} />}
                                    MenuProps={{
                                        style: {
                                            maxHeight: 300,  
                                        },
                                    }}
                          
                                >
                                    {dateOptions.reverse().map((date: any) => {
                                        return (
                                            <MenuItem key={date} value={date} className="selected-dropdown">
                                                {date}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            {vadFlag[itemProduct] ? validator.current.message(
                                "ship-date",
                                shipDate[itemProduct] ? shipDate[itemProduct] : formattedCurrentDate,
                                ["required"]
                            ) : null}
                        </Grid>
                        <Grid sx={{ mr: 2, width: "100%" }}>
                            <FormControl sx={{ mt: 1, width: "100%" }}>
                                <InputLabel>{t("oms.carrier")}</InputLabel>
                                <Select
                                    sx={{
                                        height: "auto",
                                        alignItems: "center",
                                        backgroundColor: "#f0f2f2",
                                    }}
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name={"carrier" + itemProduct}
                                    value={carrier[itemProduct] ?? ""}
                                    onChange={(e) => handleChanges(e, itemProduct)}
                                    input={<OutlinedInput label={t("oms.carrier")} />}
                                >
                                    {CONSTANTS.CARRIER_MENU_OMS.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {vadFlag[itemProduct] ? validator.current.message(
                                "carrier",
                                carrier[itemProduct],
                                ["required"]
                            ) : null}
                        </Grid>
                        {
                            carrier[itemProduct] == "その他" && (
                                <Grid sx={{ width: "100%", mr: 2 }}>
                                    <TextField
                                        name={"others" + itemProduct}
                                        value={others[itemProduct] ?? ""}
                                        label={t("oms.others")}
                                        onChange={(e) => handleChanges(e, itemProduct)}
                                        sx={{ background: "#fff", mt: 1, width: "100%" }}
                                        className="cz-order-detail-oms__textbox"
                                    ></TextField>
                                    {vadFlag[itemProduct] ? validator.current.message(
                                        "others",
                                        others[itemProduct],
                                        ["required", { maxChar100Validation: 100 },{ regex: CONSTANTS.TRACKING_ID_REGEX }]
                                    ) : null}
                                </Grid>
                            )
                        }
                        <Grid sx={{ width: "100%", mr: 2 }}>
                            <TextField
                                name={"tracking-id" + itemProduct}
                                value={trackingId[itemProduct] ?? ""}
                                onChange={(e) => handleChanges(e, itemProduct)}
                                label={t("oms.tracking_id")}
                                sx={{ background: "#fff", mt: 1, width: "100%" }}
                                className="cz-order-detail-oms__textbox"
                            ></TextField>
                            {vadFlag[itemProduct] ? validator.current.message(
                                "tracking-id",
                                trackingId[itemProduct],
                                ["required", { maxChar50Validation: 50 },{ regex: CONSTANTS.TRACKING_ID_REGEX }/*,{unique: checkTrackingId(itemProduct)}*/,"SingleByte"]
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    className="confirm-shipment-button"
                    container
                    sx={{ my:5}}
                >
                    {
                        isEdit ? (
                            <CommonButton route={window.location.pathname} functionToExecute={()=>cancelEditModel(itemProduct)} className="background_color_red" btnName={t("oms.cancel")} disableCondition={false}/>


                        // <Button
                        //     variant="contained"
                        //     color="error"
                        //     onClick={() => cancelEditModel(itemProduct)}
                        // >
                        //     {t("oms.cancel")}
                        // </Button>
                        ) : /*(
                            <Button
                                variant="contained"
                                disabled={!flag ||
                                    shipProduct[shipProduct.length - 1] != itemProduct
                                    ? true
                                    : false
                                }
                                onClick={() => handleProductChanges(itemProduct)}
                            >
                                {t("oms.add_package")}
                            </Button>
                        )*/ null
                    }
                    {
                        isEdit ? (
                            <CommonButton route={window.location.pathname} functionToExecute={() => handleEditShipment(quantity?.product[itemProduct], shipDate[itemProduct], carrier[itemProduct] == "その他" ? others[itemProduct] : carrier[itemProduct] , trackingId[itemProduct], itemProduct, confirmShipmentData?.orderId)} className="" btnName={t("oms.edit_shipment")} disableCondition={disableEditShipment(itemProduct)}/>

                        // <Button
                        //     variant="contained"
                        //     onClick={() => handleEditShipment(quantity?.product[itemProduct], shipDate[itemProduct], carrier[itemProduct] == "その他" ? others[itemProduct] : carrier[itemProduct] , trackingId[itemProduct], itemProduct, confirmShipmentData?.orderId)}
                        //     disabled={disableEditShipment(itemProduct)}
                        // >
                        //     {t("oms.edit_shipment")}
                        // </Button>
                        ) : (
                            <CommonButton route={window.location.pathname} functionToExecute={() => handleFinalShipment(quantity?.product[itemProduct], shipDate[itemProduct] ? shipDate[itemProduct] : formattedCurrentDate, carrier[itemProduct] == "その他" ? others[itemProduct] : carrier[itemProduct] , trackingId[itemProduct], itemProduct, confirmShipmentData?.orderId)} className="float_right" btnName={t("oms.ship")} disableCondition={disableConfirmShipment(itemProduct)}/>

                        // <Button className="float_right"
                        //     variant="contained"
                        //     disabled={disableConfirmShipment(itemProduct)}
                        //     onClick={() => handleFinalShipment(quantity?.product[itemProduct], shipDate[itemProduct] ? shipDate[itemProduct] : formattedCurrentDate, carrier[itemProduct] == "その他" ? others[itemProduct] : carrier[itemProduct] , trackingId[itemProduct], itemProduct, confirmShipmentData?.orderId)}
                        // >
                        //     {t("oms.ship")}
                        // </Button>
                        )
                    }
                </Grid>
            </Grid>
        </div>
    );
};

export default ConfirmOrder;
