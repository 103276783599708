import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";
import IconExpandLess from "@mui/icons-material/ExpandLess";
import IconExpandMore from "@mui/icons-material/ExpandMore";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { ROUTES } from "../../../constants/routes";
import CONSTANTS from "../../../constants/constants";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { commonActions } from "../../../redux/features/common/commonSlice";
import { newPermissionCategory } from "../../../constants/permissionCategory";
import { ProtectedMenu } from "../../../middleware/ProtectedMenu";

export default function SideNavigation() {
    const loggedInUserType = useAppSelector((state) => state.permission.permissionDetails.userType) ?? null;

    const [isOpen, setIsOpen] = useState(false);
    const [openCatalogue, setOpenCatalogue] = React.useState(false);
    const [openPayment, setOpenPayment] = React.useState(false);
    // const [openInventory, SetOpenInventory] = React.useState(false);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const toggleDrawer =
        (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === "keydown" &&
                ((event as React.KeyboardEvent).key === "Tab" ||
                    (event as React.KeyboardEvent).key === "Shift")
            ) {
                return;
            }
            setIsOpen(open);
            setOpenCatalogue(false);
            setOpenPayment(false);
        };

    function handleClickCatalogue() {
        setOpenCatalogue(!openCatalogue);

    }
    function handleClickPayment() {
        setOpenPayment(!openPayment);
    }
    function sideNavTabChange() {
        setIsOpen(false);
        dispatch(commonActions.IS_TAB_URL("1"));
        dispatch(commonActions.OMS_ORDER_FILTER_REMEMBER({}));
        // dispatch(commonActions.CANCELLED_FILTER_REMEMBER({}));
        // dispatch(commonActions.SHIPPED_FILTER_REMEMBER({}));
        // dispatch(commonActions.PREPARING_SHIPMENT_FILTER_REMEMBER({}));
        // dispatch(commonActions.REFUNDED_ORDER_FILTER_REMEMBER({}));
    }

    function handleClickClose() {
        setIsOpen(false);
        setOpenCatalogue(false);
        setOpenPayment(false);
    }

    // function handleClickInventory() {
    //     SetOpenInventory(!openInventory);
    // }

    const menuData = [
        {
            category: newPermissionCategory.MERCHANDISE_MANAGEMENT,
            element: (
                <>
                    <ListItem onClick={handleClickCatalogue} className="side-nav-bar-list">
                        <ListItemText primary={t("side_menu.merchandise_management")} />
                        {openCatalogue ? <IconExpandLess /> : <IconExpandMore />}
                    </ListItem>

                    <Collapse in={openCatalogue} timeout="auto" unmountOnExit>
                        <List component="div" className="side-navbar-list-items">
                            <Link to={ROUTES.PRODUCT_LIST} onClick={sideNavTabChange}>
                                <ListItem>
                                    <ListItemText primary={t("side_menu.product_list")} />
                                </ListItem>
                            </Link>
                            <Link to={ROUTES.BULK_PRODUCT_UPLOAD} onClick={sideNavTabChange}>
                                <ListItem>
                                    <ListItemText primary={t("side_menu.bulk_product_upload")} />
                                </ListItem>
                            </Link>

                            <Link to={ROUTES.IMAGE_UPLOAD} onClick={sideNavTabChange}>
                                <ListItem>
                                    <ListItemText primary={t("side_menu.image_Upload")} />
                                </ListItem>
                            </Link>
                            <Link to={ROUTES.BULK_IMAGE_UPLOAD} onClick={sideNavTabChange}>
                                <ListItem>
                                    <ListItemText primary={t("side_menu.bulk_image_upload")} />
                                </ListItem>
                            </Link>
                        </List>
                    </Collapse>
                </>
            )
        },
        {
            category: newPermissionCategory.INVENTORY_CONTROL_MANAGEMENT,
            element: (
                <Link to={ROUTES.MANAGE_INVENTORY} onClick={sideNavTabChange}>
                    <ListItem>
                        <ListItemText primary={t("side_menu.inventory_manage")} />
                    </ListItem>
                </Link>
            )
        },
        {
            category: newPermissionCategory.ORDER_MANAGEMENT,
            element: (
                <Link to={ROUTES.OMS} onClick={sideNavTabChange}>
                    <ListItem
                        className="side-nav-bar-list"
                    >
                        <ListItemText primary={t("side_menu.oms")} />
                    </ListItem>
                </Link>
            )
        },
        {
            category: newPermissionCategory.SALES_RECEIPT_MANAGEMENT,
            element: (
                <>
                    <ListItem onClick={handleClickPayment} className="side-nav-bar-list">
                        <ListItemText primary={t("side_menu.sales_receipt_management")} />
                        {openPayment ? <IconExpandLess /> : <IconExpandMore />}
                    </ListItem>
                    <Collapse in={openPayment} timeout="auto" unmountOnExit>
                        <List component="div" className="side-navbar-list-items">
                            <Link to={ROUTES.PAYMENT_REPORTS} onClick={sideNavTabChange}>
                                <ListItem>
                                    <ListItemText primary={t("side_menu.sales_list")} />
                                </ListItem>
                            </Link>
                            <Link to={ROUTES.SALES_HISTORY} onClick={sideNavTabChange}>
                                <ListItem>
                                    <ListItemText primary={t("side_menu.sales_history")} />
                                </ListItem>
                            </Link>
                        </List>
                    </Collapse>
                </>
            )
        },
        {
            category: newPermissionCategory.USER_MANAGEMENT,
            element: (
                <Link
                    to={ROUTES.EXHIBITION_USER_LIST}
                    onClick={sideNavTabChange}>

                    <ListItem
                        className="side-nav-bar-list"
                    >
                        <ListItemText primary={t("side_menu.user_list")} />
                    </ListItem>
                </Link>
            )
        },
        {
            category: newPermissionCategory.SUPER_ADMIN,
            element: (
                <Link to={ROUTES.ACTIVITY_LOG} onClick={() => setIsOpen(false)}>
                    <ListItem className="side-nav-bar-list">
                        <ListItemText primary={t("side_menu.activity_log")} />
                    </ListItem>
                </Link>
            )
        }
    ];

    const list = (
        <>
            <ListItem
                onClick={handleClickClose}
                className="side-nav-bar-close-section">
                <CloseRoundedIcon />
            </ListItem>

            {loggedInUserType == CONSTANTS.USER_TYPE_EXHIBITOR ? null : (

                <>
                    <List component="nav" disablePadding>
                        <ListItem onClick={handleClickCatalogue} className="side-nav-bar-list">
                            <ListItemText primary={t("side_menu.merchandise_management")} />
                            {openCatalogue ? <IconExpandLess /> : <IconExpandMore />}
                        </ListItem>

                        <Collapse in={openCatalogue} timeout="auto" unmountOnExit>
                            <List component="div" className="side-navbar-list-items">
                                <Link to={ROUTES.PRODUCT_LIST} onClick={sideNavTabChange}>
                                    <ListItem>
                                        <ListItemText primary={t("side_menu.product_list")} />
                                    </ListItem>
                                </Link>
                                <Link to={ROUTES.BULK_PRODUCT_UPLOAD} onClick={sideNavTabChange}>
                                    <ListItem>
                                        <ListItemText primary={t("side_menu.bulk_product_upload")} />
                                    </ListItem>
                                </Link>

                                <Link to={ROUTES.IMAGE_UPLOAD} onClick={sideNavTabChange}>
                                    <ListItem>
                                        <ListItemText primary={t("side_menu.image_Upload")} />
                                    </ListItem>
                                </Link>
                                <Link to={ROUTES.BULK_IMAGE_UPLOAD} onClick={sideNavTabChange}>
                                    <ListItem>
                                        <ListItemText primary={t("side_menu.bulk_image_upload")} />
                                    </ListItem>
                                </Link>
                            </List>
                        </Collapse>
                    </List>

                    <Link to={ROUTES.MANAGE_INVENTORY} onClick={sideNavTabChange}>
                        <List className="side-nav-bar-list">
                            <ListItem>
                                <ListItemText primary={t("side_menu.inventory_manage")} />
                            </ListItem>
                        </List>
                    </Link>
                    <Link to={ROUTES.OMS} onClick={sideNavTabChange}>
                        <ListItem
                            className="side-nav-bar-list"
                        >
                            <ListItemText primary={t("side_menu.oms")} />
                        </ListItem>
                    </Link>
                    <List component="nav" disablePadding>
                        <ListItem onClick={handleClickPayment} className="side-nav-bar-list">
                            <ListItemText primary={t("side_menu.sales_receipt_management")} />
                            {openPayment ? <IconExpandLess /> : <IconExpandMore />}
                        </ListItem>
                        <Collapse in={openPayment} timeout="auto" unmountOnExit>
                            <List component="div" className="side-navbar-list-items">
                                <Link to={ROUTES.PAYMENT_REPORTS} onClick={sideNavTabChange}>
                                    <ListItem>
                                        <ListItemText primary={t("side_menu.sales_list")} />
                                    </ListItem>
                                </Link>
                                <Link to={ROUTES.SALES_HISTORY} onClick={sideNavTabChange}>
                                    <ListItem>
                                        <ListItemText primary={t("side_menu.sales_history")} />
                                    </ListItem>
                                </Link>
                            </List>
                        </Collapse>
                    </List>
                    <Link
                        to={ROUTES.EXHIBITION_USER_LIST}
                        onClick={sideNavTabChange}>

                        <ListItem
                            className="side-nav-bar-list"
                        >
                            <ListItemText primary={t("side_menu.user_list")} />
                        </ListItem>
                    </Link>

                    {
                        loggedInUserType == CONSTANTS.USER_TYPE_SUPER_ADMIN ? (
                            <Link to={ROUTES.ACTIVITY_LOG} onClick={() => setIsOpen(false)}>
                                <ListItem className="side-nav-bar-list">
                                    <ListItemText primary={t("side_menu.activity_log")} />
                                </ListItem>
                            </Link>
                        ) : null
                    }

                </>
            )}
            {loggedInUserType == CONSTANTS.USER_TYPE_EXHIBITOR ? (
                <>
                    <List component="nav" disablePadding>
                        <ListItem onClick={handleClickCatalogue} className="side-nav-bar-list">
                            <ListItemText primary={t("side_menu.merchandise_management")} />
                            {openCatalogue ? <IconExpandLess /> : <IconExpandMore />}
                        </ListItem>

                        <Collapse in={openCatalogue} timeout="auto" unmountOnExit >
                            <List component="div" className="side-navbar-list-items">

                                <Link to={ROUTES.PRODUCT_LIST} onClick={sideNavTabChange}>
                                    <ListItem>
                                        <ListItemText primary={t("side_menu.product_list")} />
                                    </ListItem>
                                </Link>

                                <Link to={ROUTES.BULK_PRODUCT_UPLOAD} onClick={sideNavTabChange}>
                                    <ListItem>
                                        <ListItemText primary={t("side_menu.bulk_product_upload")} />
                                    </ListItem>
                                </Link>

                                <Link to={ROUTES.IMAGE_UPLOAD} onClick={sideNavTabChange}>
                                    <ListItem>
                                        <ListItemText primary={t("side_menu.image_Upload")} />
                                    </ListItem>
                                </Link>
                                <Link to={ROUTES.BULK_IMAGE_UPLOAD} onClick={sideNavTabChange}>
                                    <ListItem>
                                        <ListItemText primary={t("side_menu.bulk_image_upload")} />
                                    </ListItem>
                                </Link>
                            </List>
                        </Collapse>
                        <Link to={ROUTES.MANAGE_INVENTORY} onClick={sideNavTabChange}>
                            <List className="side-nav-bar-list">
                                <ListItem>
                                    <ListItemText primary={t("side_menu.inventory_manage")} />
                                </ListItem>
                            </List>
                        </Link>
                        <Link to={ROUTES.OMS} onClick={sideNavTabChange}>
                            <ListItem
                                className="side-nav-bar-list"
                            >
                                <ListItemText primary={t("side_menu.oms")} />
                            </ListItem>
                        </Link>
                        <List component="nav" disablePadding>
                            <ListItem onClick={handleClickPayment} className="side-nav-bar-list">
                                <ListItemText primary={t("side_menu.sales_receipt_management")} />
                                {openPayment ? <IconExpandLess /> : <IconExpandMore />}
                            </ListItem>
                            <Collapse in={openPayment} timeout="auto" unmountOnExit>
                                <List component="div" className="side-navbar-list-items">
                                    <Link to={ROUTES.PAYMENT_REPORTS} onClick={sideNavTabChange}>
                                        <ListItem>
                                            <ListItemText primary={t("side_menu.sales_list")} />
                                        </ListItem>
                                    </Link>
                                    <Link to={ROUTES.SALES_HISTORY} onClick={sideNavTabChange}>
                                        <ListItem>
                                            <ListItemText primary={t("side_menu.sales_history")} />
                                        </ListItem>
                                    </Link>
                                </List>
                            </Collapse>
                        </List>
                    </List>
                </>
            ) : null}
        </>
    );

    const listWithPermission = (
        <>
            <ListItem
                onClick={handleClickClose}
                className="side-nav-bar-close-section">
                <CloseRoundedIcon />
            </ListItem>
            <List component="nav" disablePadding>
                {
                    menuData?.map((item, menuIndex) => {
                        return (
                            <ProtectedMenu key={menuIndex} category={item.category}>{item?.element}</ProtectedMenu>
                        );
                    })
                }
            </List>
        </>
    );

    return (
        <>
            <React.Fragment>
                <MenuIcon sx={{ p: 2 }} onClick={() => setIsOpen(true)} />
                <Drawer
                    open={isOpen}
                    onClose={toggleDrawer(false)}
                    className="header-side-navbar"
                >
                    { loggedInUserType == CONSTANTS.USER_TYPE_SUPER_ADMIN || loggedInUserType == CONSTANTS.USER_TYPE_ADMIN ? listWithPermission : list}
                </Drawer>
            </React.Fragment>
        </>
    );
}
