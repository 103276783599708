import React, { useState, useEffect } from "react";
import {
    Container,
    Grid,
    TextField,
    InputAdornment,
    IconButton,
    Select,
    MenuItem,
    Box,
    InputLabel,
    OutlinedInput,
    FormControl,
    FormControlLabel,
    Radio,
   
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Pagination from "../../common/Pagination";
import ManageInventoryList from "../services/manageInventoryList";
import Skeleton from "../../common/Skeleton";
import SearchIcon from "@mui/icons-material/Search";
import { UpdateInventory } from "../services/updateInventory";
import {
    LIST_LIMIT,
    INVENTORY_MODULE_CONSTANTS,
    SHEET_NAME,
} from "../../../constants/constants";
import { useAppSelector } from "../../../redux/hooks";
import { Link } from "react-router-dom";
import Utility from "../../../utils/Utility";
import downloadDataToExcel from "../../../utils/ExcelDownloadUtility";
import MUIDataTable from "mui-datatables";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DownloadButton from "../../common/DownloadBtn";
import CommonButton from "../../common/Button";

const ManageInventory = (props: any) => {
    const { handleLoader, showSnackbar } = props;

    const { t } = useTranslation();
    
    const storecode = useAppSelector((state) => state?.common?.loggedInStoreCode) ?? null;

    
    useEffect(() => {
        totalPages != 0 ? productData(firstPage, limit) : null;
    }, []);
    
    const firstPage = 1;
    const [skeleton, setSkeleton] = useState(false);
    const [inventory, setInventory] = useState<any>();
    const [currentPage, setCurrentPage] = useState(firstPage);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [appliedFilter1, setAppliedFilter1] = useState<any>("none");
    const [appliedFilter2, setAppliedFilter2] = useState<any>("none");
    const [selectedValue, setSelectedValue] = useState("filter1");
    const [search, setSearch] = useState<string>("");
    const [quantity, setQuantity] = useState<string>("");
    const [limit, setLimit] = useState(LIST_LIMIT.MANAGE_INVENTORY_LIST_LIMIT);
    const [value, setValue] = useState<any>([]);
    const [hideColumnArr,setHideColumnArr] =useState<any>([]);
    const [productFilter, setProductFilter] = useState(true);
    const [quantityFilter, setQuantityFilter] = useState(false);
    const [fullWidthL, setFullWidthL] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const [colFilter,setColFilter] =useState<string>("");
    const [direction,setDirection]=useState<string>("");
    const [colName,setColName]=useState<string>("");
    const [isSearch,setIsSearch]=useState<boolean>(false);

    const apiData: any[][] = [];

    const handleSorting =(changedColumn: any)=>{
        if(colName!=changedColumn){
            setDirection("asc");

        }else{
            setDirection(direction=="asc"? "desc":"asc");
        }
        
        setColName(changedColumn);   
        const columnName=changedColumn == "Product Code" || changedColumn=="商品コード" ? "shukkamoto_mng_hinban":
            changedColumn == "Cainz Product Code" || changedColumn == "カインズ商品コード" ? "daihyo_jan_cd":
                changedColumn == "Product Name" || changedColumn=="商品名" ? "prd_nm_kj":
                    changedColumn == "Product Price including Tax" || changedColumn=="税込商品価格" ? "bai_tanka_idx_zeikomi_1":
                        changedColumn == "Individual Shipping Fee" || changedColumn=="個別送料" ? "kobetsu_soryo_kn_ec":
                            "";   
        setColFilter(columnName);

    };

    const columns = [
        {
            name: t("bulk_product_index.sno"),
            options: {
                filter: true,
                sort: false,
                viewColumns: false
            },
        },
        {
            name: t("bulk_product_index.product_code"),
            options: {
                filter: false,
                sort: false,
                display: hideColumnArr.includes("商品コード") ? false : true,
                customHeadLabelRender: (data: any) => {
                    return (
                        <Box>
                            {data.label}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="shukkamoto_mng_hinban" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>  
                        </Box>
                    );
                },   
            },
        },
        {
            name: t("bulk_product_index.cainz_product_code"),
            options: {
                filter: true,
                sort: false,
                viewColumns: false,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box>
                            {data.label}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="daihyo_jan_cd" ? <SwapVertIcon className="swapVertIcon"/> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>  
                        </Box>
                    );
                },   
            },
        },
        {
            name: t("bulk_product_index.product_name"),
            options: {
                filter: true,
                sort: false,
                display: hideColumnArr.includes("商品名") ? false : true,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box>
                            {data.label}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="prd_nm_kj" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>  
                        </Box>
                    );
                },   
            },
        },
        {
            name: t("bulk_product_index.product_price_tax"),
            options: {
                filter: true,
                sort: false,
                display: hideColumnArr.includes("税込商品価格") ? false : true,
                setCellHeaderProps: () => ({className: "text_right"}),
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box>
                            {data.label}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="bai_tanka_idx_zeikomi_1" ? <SwapVertIcon className="swapVertIcon"/> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>  
                        </Box>
                    );
                },   
            },
        },
        {
            name: t("bulk_product_index.shipping_fee"),
            options: {
                filter: true,
                sort: false,
                display: hideColumnArr.includes("個別送料") ? false : true,
                setCellHeaderProps: () => ({className: "text_right"}),
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box>
                            {data.label}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="kobetsu_soryo_kn_ec" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>  
                        </Box>
                    );
                },   
            },
        },
        {
            name: t("bulk_product_index.quantity"),
            options: {
                filter: true,
                sort: false,
                viewColumns: false,
                setCellHeaderProps: () => ({className: "text_right"}), 
            },
        },
        {
            name: t("bulk_product_index.public_flag"),
            options: {
                filter: true,
                sort: false,
                display: hideColumnArr.includes("公開フラグ") ? false : true,
            },
        },
        {
            name: t("bulk_product_index.action"),
            options: {
                filter: true,
                sort: false,
                viewColumns: false
            },
        },
    ];


    useEffect(()=>{
        if(colFilter||direction){
            productData(firstPage, limit);  
        }
       
    },[colFilter, direction]);

    useEffect(()=>{
        setHideColumnArr([]);
    },[t]); 

    useEffect(() => {
        productData(firstPage, limit);
    }, [appliedFilter1, appliedFilter2]);




    const productData = (currentPage: number, limit: number) => {
        const payload = {};
        const isHitDummyURL = false;
        setSkeleton(true);
        new ManageInventoryList(payload, isHitDummyURL)
            .inventoryListData(
                currentPage,
                limit,
                storecode ?? 0,
                search.trim(),
                appliedFilter1,
                appliedFilter2,
                quantity,
                colFilter,
                direction
            )
            .then((res) => {
                setInventory(res);
                setLimit(limit);
                setCurrentPage(currentPage);
                setTotalCount(res?.pagination?.totalCount);
                setTotalPages(res?.pagination?.totalPages);
                setSkeleton(false);
                handleInputQuantity(res);
            })
            .catch((err: any) => {
                setSkeleton(false);
                showSnackbar(err?.message, false);

            });
    };

    const handleInputQuantity = (quantities: any) => {
        const quantityJSON : any = {};
        quantities?.inventories?.map((item: any) => {
            quantityJSON[item?.productCode]= item?.quantity;
        });
        setValue(quantityJSON);
    };

    const downloadProductData = () => {
        showSnackbar(t("bulk_product_index.download_started"), true);
        const payload = {};
        const isHitDummyURL = false;
        setLoading(true);
        // setSuccess(false);
        new ManageInventoryList(payload, isHitDummyURL)
            .downloadInventoryListData(
                storecode ?? 0,
                search.trim(),
                appliedFilter1,
                appliedFilter2,
                quantity,
                colFilter,
                direction
            )
            .then((res) => {
                handleOnExport(res?.inventories);
            })
            .catch((err: any) => {
                setLoading(false);
                setSkeleton(false);
                showSnackbar(err?.message, false);
            });    
    };

    const updateUnregisteredInventoryStatus = (cainzProductCode: number, productCode: any) => {
        const payload = { productCode: productCode, cainzProductCode: cainzProductCode };
        const isHitDummyURL = false;
        new UpdateInventory(payload, isHitDummyURL)
            .inventoryStatusUpdate()
            .then((res) => {
                showSnackbar(res, true);
            })
            .catch((err: any) => {
                handleLoader(false);
                showSnackbar(err?.message, false);
            });
    };
    
    const UpdateManageInventory = (productId: number, ProductCode: any) => {
        if (value[ProductCode] || value[ProductCode] == 0 ) {
            handleLoader(true);
            const payload = { productCode: productId, quantity: value[ProductCode] };
            const isHitDummyURL = false;
            new UpdateInventory(payload, isHitDummyURL)
                .inventoryListUpdateData(productId)
                .then((res) => {
                    updateUnregisteredInventoryStatus(productId,ProductCode);
                    handleLoader(false);
                    showSnackbar(res, true);
                })
                .catch((err: any) => {
                    handleLoader(false);
                    showSnackbar(err?.message, false);
                });
        }
    };

    const handleIconPress = () => {
        setIsSearch(true);
        productData(firstPage, limit);
    };

    const handleKeyPress = (e: any) => {
        if (e.keyCode == 13) {
            setIsSearch(true);
            productData(firstPage, limit);
        }
    };

    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setSearch(value);
    };

    const handleQuantityChange = (e: any) => {
        const { value } = e.target;
        setQuantity(value);
    };


    const handleFlagFilterChange = (e: any) => {
        const { value } = e.target;
        setAppliedFilter1(value);
    };

    const handleUnregisteredFilterChange = (e: any) => {
        const { value } = e.target;
        setAppliedFilter2(value);
    };

    const handleOnExport = (downloadArray?: any) => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate= `${year}${month}${day}`;
        const downloadData: Array<object> = [];
        downloadArray?.map((item: any) => {
            const data:any = {
                商品コード:item?.productCode,
                カインズ商品コード: item?.cainzProductCode,
                商品名: item?.productName,
                税込商品価格:item?.productPrice,
                個別送料:item?.shippingFee,
                在庫数: item?.quantity,
                公開フラグ:item?.publicFlag==1 ? "公開" : "非公開",
            };
            if(hideColumnArr?.length>0){
                hideColumnArr?.map((key:any)=>{
                    delete data[key];
                });
            }
            downloadData.push(data);
        });
        
        const fileName = `在庫一覧_${storecode}_${formattedDate}.xlsx`;
        const numFmt="text";
        downloadDataToExcel(downloadData,fileName,SHEET_NAME.MANAGE_INVENTORY,numFmt);
        setLoading(false);
    };

    const actionButton = (id: number, ProductCode: any) => {
        return (
            <CommonButton route={window.location.pathname} functionToExecute={() => UpdateManageInventory(id, ProductCode)} className="" btnName={t("product_list.save")} disableCondition={!id || value[ProductCode] === ""} />
        );
    };
    
    const min = 0;
    const max = 9999999;

    const quantityInput = (amount: number, cainzProductCode: any, ProductCode: any) => {
        return (
            <Box className={`${ProductCode < 0 ? "text_right" : "text_right"}`}>
                <Box className="qua_input">
                    <TextField
                        sx={{textAlign:"right"}}
                        type="number"
                        inputProps={{ min, max }}
                        value={value[ProductCode]}
                        disabled={ cainzProductCode == "" ? true : false }
                        onChange={(e) => {
                            let values: any = e.target.value == "" ? "" : parseInt(e.target.value, 10);
                            if (values > max) values = max;
                            if (values < min) values = min;
                            e.target.value = values.toString();
                            setValue((prev: any) => ({ ...prev, [ProductCode]: values }));
                        }}
                    />
                </Box>
            </Box>
        );
    };

    const productNameLink = (name: any, id: number) => {
        return (
            <Link to={`${process.env.REACT_APP_PRODUCT_BASE_URL}/${id}.html`} target="_blank" rel="noopener noreferrer" className="Order_id_link">
                {name}
            </Link>
        );
    };

    const rightAlignText = (value:any) =>{
        return(
            <Box className={`${value < 0 ? "product_text_right" : "product_text_right"}`}>{new Utility().numberWithCommas(value)}</Box>
        );
    };

    const handleFilter1 = () => {
        if(isSearch) {
            setIsSearch(false);
            const payload = {};
            const isHitDummyURL = false;
            setSkeleton(true);
            new ManageInventoryList(payload, isHitDummyURL)
                .inventoryListData(
                    currentPage,
                    limit,
                    storecode ?? 0,
                    search.trim(),
                    appliedFilter1,
                    appliedFilter2,
                    "",
                    colFilter=="quantity" ? "" : colFilter,
                    colFilter=="quantity" ? "" : direction
                )
                .then((res) => {
                    setInventory(res);
                    setLimit(limit);
                    setCurrentPage(currentPage);
                    setTotalCount(res?.pagination?.totalCount);
                    setTotalPages(res?.pagination?.totalPages);
                    setSkeleton(false);
                    handleInputQuantity(res);
                })
                .catch((err: any) => {
                    setSkeleton(false);
                    showSnackbar(err?.message, false);
                });
        }
    };

    const handleFilter2 = () => {
        if(isSearch) {
            setIsSearch(false);
            const payload = {};
            const isHitDummyURL = false;
            setSkeleton(true);
            new ManageInventoryList(payload, isHitDummyURL)
                .inventoryListData(
                    currentPage,
                    limit,
                    storecode ?? 0,
                    "",
                    "",
                    quantity,
                    colFilter,
                    direction
                )
                .then((res) => {
                    setInventory(res);
                    setLimit(limit);
                    setCurrentPage(currentPage);
                    setTotalCount(res?.pagination?.totalCount);
                    setTotalPages(res?.pagination?.totalPages);
                    setSkeleton(false);
                    handleInputQuantity(res);
                })
                .catch((err: any) => {
                    setSkeleton(false);
                    showSnackbar(err?.message, false);
                });
        }
    };



    const apiTableData = () => {
        {
            inventory?.inventories?.map((item: any, index: number) => {
                apiData[index] = [];
                apiData[index].push(
                    (currentPage - 1) * limit + index + 1,
                    item?.productCode,
                    item?.cainzProductCode,
                    productNameLink(item?.productName, item?.cainzProductCode),                  
                    rightAlignText( item?.productPrice),
                    rightAlignText(item?.shippingFee),
                    quantityInput(value[item?.productCode], item?.cainzProductCode, item?.productCode),
                    item?.publicFlag == INVENTORY_MODULE_CONSTANTS.STATUS_YES
                        ? t("bulk_product_index.public")
                        : t("bulk_product_index.private"),
                    actionButton(item?.cainzProductCode, item?.productCode)
                );
            });
        }
    };
    apiTableData();

    const handleProductFilter = (event: any) => {
        setSelectedValue(event.target.value);
        setProductFilter(true);
        setQuantityFilter(false);
        setQuantity("");
        setAppliedFilter1("none");
        setAppliedFilter2("none");
        if(colFilter=="quantity"){
            setColFilter("");
            setDirection("");
            setColName("");
        }
    };

    const handleQuantityFilter = (event: any) => {
        setSelectedValue(event.target.value);
        setProductFilter(false);
        setQuantityFilter(true);
        setSearch("");
        setAppliedFilter1("none");
        setAppliedFilter2("none");
    };

    const handleInputType = (e: any) => {
        e.target.value = e.target.value.replace(/[^0-9||０-９]/g, "");
        if(e.target.value.match(/[\uff00-\uffff]/g)){
            setFullWidthL(1);
            if(fullWidthL==0){
                e.target.value = e.target.value.replace(/[\uFF10-\uFF19]/g, function(m:any) {
                    return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
                });
            }else{
                setFullWidthL(0);
                e.target.value = e.target.value.replace(/[\uFF10-\uFF19]/g, function() {
                    return "";
                });          
            }  
        }
    };




    return (

        <React.Fragment>
            <Box className="manage_inventory">
                <Container sx={{ my: 4 }}>
                    <Grid container spacing={2} sx={{ mb: 5 }}>
                        <Grid item xs={12} sm={6} md={3} lg={3} sx={{ py: 1, px: 2 }}>
                            <FormControlLabel checked={selectedValue === "filter1"} value="filter1" onChange={handleProductFilter} onClick={handleFilter1} control={<Radio />} label={t("bulk_product_index.filter_by_product")} />
                            <FormControlLabel checked={selectedValue === "filter2"} value="filter2" onChange={handleQuantityFilter} onClick={handleFilter2} control={<Radio />} label={t("bulk_product_index.filter_by_quantity")} />
                        </Grid>
                        {
                            productFilter ? (
                                <>
                                    <Grid item xs={12} sm={6} md={3} lg={3} sx={{ py: 1, px: 2 }}>
                                        <TextField
                                            label={t("bulk_product_index.search_place_holder")}
                                            onChange={handleSearchChange}
                                            onKeyUp={handleKeyPress}
                                            value={search}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => handleIconPress()}>
                                                            <SearchIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={2} lg={2}>
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="demo-multiple-name-label">
                                                {t("bulk_product_index.public_flag")}
                                            </InputLabel>
                                            <Select
                                                id="demo-simple-select"
                                                size="small"
                                                value={appliedFilter1}
                                                onChange={(e) => handleFlagFilterChange(e)}
                                                fullWidth
                                                input={
                                                    <OutlinedInput label={t("bulk_product_index.public_flag")} />
                                                }>
                                        
                                                <MenuItem value="false">{t("bulk_product_index.private")}</MenuItem>
                                                <MenuItem value="true">{t("bulk_product_index.public")}</MenuItem>
                                                <MenuItem value="none">{t("bulk_product_index.all")}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={2} lg={2} sx={{ py: 1, px: 2 }}>
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="demo-multiple-name-label">
                                                {t("bulk_product_index.stock_number")}
                                            </InputLabel>
                                            <Select
                                                id="demo-simple-select"
                                                size="small"
                                                value={appliedFilter2}
                                                onChange={(e) => handleUnregisteredFilterChange(e)}
                                                fullWidth
                                                input={
                                                    <OutlinedInput label={t("bulk_product_index.stock_number")} />
                                                }>
                                                <MenuItem value="false">{t("bulk_product_index.not_set")}</MenuItem>
                                                <MenuItem value="none">{t("bulk_product_index.all")}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </>
                            ) : null
                        }
                    
                        {
                            quantityFilter ? (
                                <>
                                    <Grid item xs={12} sm={6} md={3} lg={4}></Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={3}>
                                        <TextField
                                            label={t("bulk_product_index.in_stock_filter")}
                                            onChange={handleQuantityChange}
                                            onKeyUp={handleKeyPress}
                                            value={quantity}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => handleIconPress()}>
                                                            <SearchIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onInput={(e) => handleInputType(e)}   
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>
                                </>
                            ) : null
                        }

                        <Grid item xs={12} sm={6} md={3} lg={2} sx={{position: "relative"}}>
                            <DownloadButton loading={loading}  route={window.location.pathname} functionToExecute={() => downloadProductData()} className="product_list_download" btnName= {t("bulk_product_index.batch_download")} disableCondition={loading || inventory?.inventories?.length == 0 || !inventory?.inventories} />
                        </Grid>
                    </Grid>
                </Container>

                {skeleton ? (
                    <Skeleton
                        varient="rectangular"
                        width={0}
                        height={60}
                        count="10"
                        my={0}
                    />
                ) : (
                    <div className="main">
                        <Container className="product_list_table first_table_cell_small_width">
                            <MUIDataTable
                                title={""}
                                columns={columns}
                                data={apiData}
                                options={{
                                    pagination: false,
                                    print: false,
                                    download: false,
                                    filter: false,
                                    search: false,
                                    selectableRowsHideCheckboxes: true,
                                    textLabels: {
                                        body: {
                                            noMatch: t("table_text.no_match").toString(),
                                        },
                                        viewColumns: {
                                            title: t("table_text.show_columns").toString(),
                                            titleAria: "Show/Hide Table Columns",
                                        },
                                        toolbar: {
                                            search: t("table_text.search").toString(),
                                            viewColumns: t("table_text.view_columns").toString(),
                                        },
                                    },
                                    searchPlaceholder: t("table_text.search_placeholder").toString(),
                                    responsive: "standard",
                                    onSearchChange: (searchText: any) => {
                                        setSearch(searchText ? searchText : "");
                                    },
                                    onViewColumnsChange: (changedColumn:string,action:string) => {
                                        const tempHideColArr=[...hideColumnArr];
                                        if(action==="remove"){
                                            changedColumn=="Product Code"?
                                                tempHideColArr.push("商品コード"):
                                                changedColumn=="Product Name"?
                                                    tempHideColArr.push("商品名"):
                                                    changedColumn=="Product Price including Tax"?
                                                        tempHideColArr.push("税込商品価格"):
                                                        changedColumn=="Individual Shipping Fee"?
                                                            tempHideColArr.push("個別送料"):
                                                            changedColumn=="Public Flag (Sales posting flag)"?
                                                                tempHideColArr.push("公開フラグ"):
                                                                tempHideColArr.push(changedColumn);
                                            setHideColumnArr(tempHideColArr);
                                        }
                                        else{
                                            let index=-1;
                                            changedColumn=="Product Code"?
                                                index = tempHideColArr.indexOf("商品コード"):
                                                changedColumn=="Product Name"?
                                                    index = tempHideColArr.indexOf("商品名"):
                                                    changedColumn=="Product Price including Tax"?
                                                        index = tempHideColArr.indexOf("税込商品価格"):
                                                        changedColumn=="Individual Shipping Fee"?
                                                            index = tempHideColArr.indexOf("個別送料"):
                                                            changedColumn=="Public Flag (Sales posting flag)"?
                                                                index = tempHideColArr.indexOf("公開フラグ"):
                                                                index = tempHideColArr.indexOf(changedColumn);
                                            tempHideColArr.splice(index,1);
                                            setHideColumnArr(tempHideColArr);
                                        }
                                    }

                                }}
                            />
                        </Container>
                        <Pagination
                            totalCount={totalCount}
                            currentPage={currentPage}
                            pageChange={productData}
                            totalPage={totalPages}
                            rowsPerPage={limit}
                            rowsPerPageChange={setLimit}
                        />
                    </div>
                )}
            </Box>
        </React.Fragment>
    );
};

export default ManageInventory;
