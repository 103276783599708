import * as ExcelJS from "exceljs";


const downloadDataToExcel=(data:Array<object>,fileName:string,sheetName:string,numFmt="")=>{
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(sheetName);
    // Add headers
    const headers = Object.keys(data[0]);
    worksheet.addRow(headers);
    // Add data
    data.forEach((item:any) => {
        const row = Object.values(item);
        worksheet.addRow(row);
    });
    // Set font style for the entire sheet
    const fontStyle = { name: "Yu Gothic" };
    if(numFmt!="text"){
        worksheet.eachRow((row) => {
            row.eachCell((cell) => {
                cell.font = fontStyle;
            });
        });
    }
    if(numFmt=="text"){
        headers.forEach((header, columnIndex) => {
            worksheet.getColumn(columnIndex + 1).numFmt = "@";
            worksheet.getColumn(columnIndex + 1).font = fontStyle;
        });
    }
    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.click();
    });
};

export default downloadDataToExcel;

/**
 * Function to download an Excel file with a specific sheet copied from a S3 template file.
 * @param data - An array of objects representing the data to be added to the sheet.
 * @param fileName - The name of the Excel file to be downloaded.
 * @param sheetName - The name of the new sheet to be created.
 * @param numFmt - Optional parameter to format columns as text ("text") or keep default.
 * @param categoryTemplateUrl - URL of the Excel S3 template file to fetch and use.
 */

export const downloadExcelWithTemplate = async (data:Array<object>, fileName:string, sheetName:string, numFmt="", categoryTemplateUrl:string , showSnackbar :any, setLoading:any ) => {
    try {
        const response = await fetch(categoryTemplateUrl);
        const arrayBuffer = await response.arrayBuffer();
    
        // Step 2: Load the workbook using ExcelJS
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(arrayBuffer);
    
        const targetSheet = workbook.getWorksheet("ECカテゴリ");
        if (!targetSheet) {
            showSnackbar("Sheet \"ECカテゴリ\" not found.", false);
            return;
        }
    
        // Step 4: Create a new workbook and add the target sheet with styles
        const newWorkbook = new ExcelJS.Workbook();
        const worksheet = newWorkbook.addWorksheet(sheetName);
        const headers = Object.keys(data[0]);
        worksheet.addRow(headers);
        data.forEach((item:any) => {
            const row = Object.values(item);
            worksheet.addRow(row);
        });
        const fontStyle = { name: "Yu Gothic" };
        if(numFmt!="text"){
            worksheet.eachRow((row) => {
                row.eachCell((cell) => {
                    cell.font = fontStyle;
                });
            });
        }
        if(numFmt=="text"){
            headers.forEach((header, columnIndex) => {
                worksheet.getColumn(columnIndex + 1).numFmt = "@";
                worksheet.getColumn(columnIndex + 1).font = fontStyle;
            });
        }
        const newSheet = newWorkbook.addWorksheet(targetSheet.name);
    
        targetSheet.columns.forEach((col, index) => {
            const newCol = newSheet.getColumn(index + 1);
            newCol.width = col.width;
        });
    
        targetSheet.eachRow((row, rowNumber) => {
            const newRow = newSheet.getRow(rowNumber);
            row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
                const newCell = newRow.getCell(colNumber);
                newCell.value = cell.value;
                if (cell.style) {
                    newCell.style = { ...cell.style };
                }
            });
            newRow.commit();
        });
        const buffer = await newWorkbook.xlsx.writeBuffer();
    
        // Step 8: Trigger download for the new workbook
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
    
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);
    } catch (error) {
        setLoading(false);
        console.error("Error while processing the Excel file:", error);
        showSnackbar(`Error while processing the Excel file: ${error}`, false);
    }
};
