import { API } from "../../../config";
import { LIST_LIMIT } from "../../../constants/constants";
import { bulkProductTypeypeWithPaginationType } from "../../../interfaces/bulkProductListInterface";
import { shippingFeeOptionsType } from "../../../interfaces/shippingFeeOptionsInterface";
import PaginationModel from "../../../models/Pagination/PaginationModel";
import ProductListWithPaginationModel from "../../../models/Products/ProductListWithPaginationModel";
import ProductModel from "../../../models/Products/ProductModel";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { serverAPIPath, mockAPIPath } from "../config/apiPath";
class ProductListApi {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;
    then: any;
    
    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async productlistData(page: number, search?: string, limit = LIST_LIMIT.EXHIBITION_USER_LIST_LIMIT) {
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ?
            await new AxiosUtils(`${mockAPIPath.PRODUCT_LIST}`, this.payload).getNew() :
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.PRODUCT_LIST}?page=${page}&limit=${limit}&search=${search}`, this.payload).getNew();
        let productPaginationModel:ProductListWithPaginationModel = new ProductListWithPaginationModel();
        const productList:ProductModel[] = [];
      
        if(response?.data?.success==true)
        {
            response?.data?.data.forEach((item: any) => {
                let products:ProductModel = new ProductModel();
                products = ProductModel.parseJson(item);
                productList.push(products);
            });
            
            let paginationData:PaginationModel = new PaginationModel();
            paginationData = PaginationModel.parseJson(response?.data);

            productPaginationModel = ProductListWithPaginationModel.parseJson(productList,paginationData);
           
            return productPaginationModel;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }

    async productlistDataNew(
        page_id: number,
        search?: string,
        limit = LIST_LIMIT.BULK_PRODUCT_LIST,
        flag?:any,
        productCode?:any,
        individualShippingFee?:number,
        colName?:string,
        order?:string
    ) {
    
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.PRODUCT_LIST}`,
                this.payload,
                this.headers
            ).getNew()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.PRODUCT_LIST}?page=${page_id}&limit=${limit}&search=${search}&flag=${flag}&isProductCode=${productCode}&individualShippingFee=${individualShippingFee}&column=${colName}&order=${order}`,
                this.payload,
                this.headers
            ).getNew();
        if (response?.data?.success) {
            const { data, currentPage, totalPages, currentTotalCount, totalCount, templateUrl } =
        response.data.data;
            const bulkProductData: bulkProductTypeypeWithPaginationType = {
                bulkProductLogs: data,
                pagination: {
                    currentPage,
                    totalPages,
                    currentTotalCount,
                    totalCount,
                },
                templateUrl
            };
            return bulkProductData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async productlistDownload(
        search?: string,
        flag?:any,
        productCode?:string,
        individualShippingFee?:number,
        fileType?:string,
        colName?:string,
        order?:string
    ) {
       
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.PRODUCT_LIST}`,
                this.payload,
                this.headers
            ).getNew()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.PRODUCT_LIST}?search=${search}&flag=${flag}&isProductCode=${productCode}&individualShippingFee=${individualShippingFee}&fileType=${fileType}&download=true&column=${colName}&order=${order}`,
                this.payload,
                this.headers
            ).getNew();
        if (response?.data?.success) {
            if(response?.data?.data) {
                const { data, currentPage, totalPages, currentTotalCount, totalCount, templateUrl } =
        response.data.data;
           
                const bulkProductData: bulkProductTypeypeWithPaginationType = {
                    bulkProductLogs: data,
                    pagination: {
                        currentPage,
                        totalPages,
                        currentTotalCount,
                        totalCount,
                    },
                    templateUrl
                };
                return bulkProductData;
            } else {
                const message = response?.data?.message;
                return message;
            }
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async shippingFeeFilterOptions() {   
        const response = await new AxiosUtils(
            `${API.BASE_URL}${serverAPIPath.SHIPPING_FEE_FILTER_OPTIONS}`,
            this.payload,
            this.headers
        ).get();
        if (response?.data?.success) {
            const shippingFeeOptions : shippingFeeOptionsType =response?.data?.data;
            return shippingFeeOptions;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async downloadProductResponseCheck(url:any) {       
        const response = await new AxiosUtils(url).getRawResponse();
        if(response?.status==200){
            return true;
        }
        else{
            return false;
        }  
    }
}

export default ProductListApi;

