import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import CONSTANTS from "../../constants/constants";
import { useAppSelector } from "../../redux/hooks";

const Timer = (props: any) => {

    const { minutes, seconds, operation, message1, message2 } = props;
    const [time, setTime] = useState({ minutes: +(minutes), seconds: +(seconds) });
    const [isRed, setIsRed] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const updatedTimerValue = useAppSelector(state => state.common.timerValue) ?? null;
    let min: any = 0;
    let sec: any = 0;

    useEffect(() => {
        const countdown = setInterval(() => {
            if (time.minutes === 0 && time.seconds === 0) {
                clearInterval(countdown);
                // function execution when the timer reaches 00:00 here
                operation(true);
            } else {
                let currentDateTime = new Date().getTime();
                currentDateTime = Math.floor((currentDateTime/1000));
                const timeLeft = updatedTimerValue - currentDateTime;
                if(timeLeft>0){
                    min = Math.floor(timeLeft/60);
                    sec = Math.floor(timeLeft-(min*60));
                }

                setTime({ minutes: (min), seconds: (sec) });

                if(min < +(CONSTANTS.TIMER_COLOR_CHANGE_MIN)){
                    setIsRed(true);
                }else if(min == +(CONSTANTS.TIMER_COLOR_CHANGE_MIN)){
                    if(sec <= +(CONSTANTS.TIMER_COLOR_CHANGE_SEC)){
                        setIsRed(true);
                    }
                }

                if(min < +(CONSTANTS.TIMER_VISIBILITY_MIN)){
                    setIsShow(true);
                }else if(min == +(CONSTANTS.TIMER_VISIBILITY_MIN)){
                    if(sec <= +(CONSTANTS.TIMER_VISIBILITY_SEC)){
                        setIsShow(true);
                    }
                }

            }
        }, 1000);
        return () => clearInterval(countdown);
    }, [time]);

    // Helper function to format single digits with leading zero
    const formatTime = (value: any) => (value < 10 ? `0${value}` : value);

    // Dynamic style based on the isRed state
    const timerStyle = {
        color: isRed ? "red" : "black",
    };

    return (
        <>
            {
                isShow ? (
                    <Box component="span" sx={{ pr: 1 }} className="timer-background">
                        {message1}
                        <Box className="bold" component="span" style={timerStyle}>
                            {" "}{`${formatTime(time.minutes)}:${formatTime(time.seconds)}`}{" "}
                        </Box>
                        {message2}
                    </Box>
                ) : null
            }
        </>
    );
};

export default Timer;