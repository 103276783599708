import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import CommonType, { commonInitialValue } from "../../../interfaces/commonInterface";

const initialState: CommonType = commonInitialValue;

const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        CLEAR_COMMON_REDUX: () => initialState,

        IS_STORE_CREATED: (state, action: PayloadAction<boolean>) => {
            state.isStoreCreated = action.payload;
        },
        // RESET_IS_STORE_CREATED: (state, action) => {
        //     state.isStoreCreated = action.payload;
        // },
        SAVE_LOGIN_USER_EMAIL: (state, action: PayloadAction<string>) => {
            state.loggedInUserEmail = action.payload;
        },
        SAVE_LOGIN_USER_NAME: (state, action: PayloadAction<string>) => {
            state.loggedInUserName = action.payload;
        },
        SAVE_LOGIN_ADMIN_USER_NAME: (state, action: PayloadAction<string>) => {
            state.loggedInAdminUserName = action.payload;
        },
        SAVE_STORE_ID: (state, action: PayloadAction<number>) => {
            state.loggedInStoreId = action.payload;
        },
        SAVE_STORE_NAME: (state, action: PayloadAction<string>) => {
            state.loggedInStoreName = action.payload;
        },
        SAVE_STORE_CODE: (state, action: PayloadAction<number>) => {
            state.loggedInStoreCode = action.payload;
        },
        // SAVE_LOGIN_USER_TYPE: (state, action: PayloadAction<number>) => {
        //     state.loggedInUserType = action.payload;
        // },
        SAVE_ORDER_ID: (state, action: PayloadAction<any>) => {
            state.isOrderIdStored = action.payload;
        },
        SAVE_ORDER_DATA: (state, action: PayloadAction<any>) => {
            state.isOrderDataStored = action.payload;
        },

        SAVE_FILE_NAME:(state, action: PayloadAction<string>) => {
            state.isFileNameStored = action.payload;
        },
        SAVE_FILE_NAME_BULK:(state, action: PayloadAction<string>) => {
            state.isFileNameStoredBulk = action.payload;
        },
        SAVE_FILE_NAME_INVENTORY:(state, action: PayloadAction<string>) => {
            state.isFileNameStoredInventory = action.payload;
        },
        SAVE_OPEN_PROGRESSBAR:(state, action: PayloadAction<any>) => {
            state.isOpenProgressbar = action.payload;
        },
        SAVE_OPEN_PROGRESSBAR_INVENTORY:(state, action: PayloadAction<any>) => {
            state.isOpenProgressbarInventory = action.payload;
        },
        PROGRESSBAR_MESSAGE:(state, action: PayloadAction<any>) => {
            state.isProgressbarMessage = action.payload;
        },
        SAVE_OPEN_PROGRESSBAR_BULK_IMAGE_UPLOAD:(state, action: PayloadAction<any>) => {
            state.isOpenProgressbarBulkImageUpload = action.payload;
        },
        PROGRESS_PERCENT_BULK_IMAGE_UPLOAD:(state, action: PayloadAction<any>) => {
            state.bulkImageUploadProgress = action.payload;
        },
        PROGRESS_PERCENT_BULK_PRODUCT_UPLOAD:(state, action: PayloadAction<any>) => {
            state.bulkProductUploadProgress = action.payload;
        },
      
        PROGRESSBAR_MESSAGE_INVENTORY:(state, action: PayloadAction<any>) => {
            state.isProgressbarMessageInventory = action.payload;
        },

        PROGRESSBAR_MESSAGE_BULK_IMAGE_UPLOAD:(state, action: PayloadAction<any>) => {
            state.isProgressbarMessageBulkImageUpload = action.payload;
        },

        IS_RELOAD:(state, action: PayloadAction<any>) => {
            state.isReloadPage = action.payload;
        },
        IS_TAB_URL:(state, action: PayloadAction<string>) => {
            state.isTabUrl = action.payload;
        },
        IS_PRODUCT_UPLOAD:(state, action: PayloadAction<any>) => {
            state.productData = action.payload;
        },
        PRODUCT_UPLOAD_JSON:(state, action: PayloadAction<any>) => {
            state.productDataJson = action.payload;
        },
        OMS_ORDER_FILTER_REMEMBER:(state, action: PayloadAction<any>) => {
            state.backOrderFilter = action.payload;
        },
        REFUNDED_ORDER_FILTER_REMEMBER:(state, action: PayloadAction<any>) => {
            state.refundOrderFilter = action.payload;
        },
        IS_MAINTENANACE: (state, action: PayloadAction<string>) => {
            state.isMaintenance = action.payload;
        },
        IMAGE_PAGINATION_DATA: (state, action: PayloadAction<any>) => {
            state.imagePagination = action.payload;
        },
        SET_IMAGE_UPLOAD_PARAM: (state, action: PayloadAction<any>) => {
            state.rememberImageUploadParams = action.payload;
        },
        SET_SELECTED_PRODUCT: (state, action: PayloadAction<any>) => {
            state.selectedProduct = action.payload;
        },
        // NEW_PERMISSION: (state, action: PayloadAction<any>) => {
        //     state.newPermission = action.payload;
        // },
        IS_UNSHIPPED: (state, action: PayloadAction<any>) => {
            state.unshipped = action.payload;
        },
        IS_SHIPPED: (state, action: PayloadAction<any>) => {
            state.shipped = action.payload;
        },
        IS_PRODUCT_LIST: (state, action: PayloadAction<any>) => {
            state.productList = action.payload;
        },
        PRODUCT_LIST_COLUMN_FILTER: (state, action: PayloadAction<any>) => {
            state.productListFilter = action.payload;
        },
        IMAGE_LIST_FILTER: (state, action: PayloadAction<any>) => {
            state.imageListFilter = action.payload;
        },
        HIDE_COLUMN_FILTER: (state, action: PayloadAction<any>) => {
            state.hideColumnFilter = action.payload;
        },
        IS_PREPARING_SHIPPMENT: (state, action: PayloadAction<any>) => {
            state.preparingShipment = action.payload;
        },

        IS_CANCEL: (state, action: PayloadAction<any>) => {
            state.cancelled = action.payload;
        },
        IS_DOWNLOAD: (state, action: PayloadAction<any>) => {
            state.orderStatus = action.payload;
        },
        IS_REFUND: (state, action: PayloadAction<any>) => {
            state.refundedOrders = action.payload;
        },
        SAVE_PER_PAGE_LIMIT: (state, action: PayloadAction<any>) => {
            state.perPageLimit = action.payload;
        },
        SAVE_TIMER_VALUE: (state, action: PayloadAction<any>) => {
            state.timerValue = action.payload;
        },
        SAVE_BROWSER_DIALOG_BOX_TIME: (state, action: PayloadAction<any>) => {
            state.dialogBoxTimerValue = action.payload;
        },
    }   
});

export default commonSlice.reducer;
export const commonActions = commonSlice.actions;