import React, { useEffect, useState, useRef } from "react";
import {
    Button,
    Container,
    Typography,
    Box,
    Grid,
    Divider,
    Checkbox,
    TableContainer,
    Paper,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    Table,
    TextField,
    InputAdornment,
    IconButton,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import CONSTANTS, { LIST_LIMIT, OMS_CONSTANTS, PARAMS_CONSTANTS } from "../../../constants/constants";
import OmsManageOrderService from "../service/OmsManageOrderService";
import { useTranslation } from "react-i18next";
import Pagination from "../../common/Pagination";
import { useNavigate, useParams } from "react-router";
import { ROUTES } from "../../../constants/routes";
import Skeleton from "../../common/Skeleton";
import { commonActions } from "../../../redux/features/common/commonSlice";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import Utility from "../../../utils/Utility";
import { OMSConfig } from "../config/OMSConfig";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import SimpleReactValidator from "simple-react-validator";
import lazyTypingUtils from "../../../utils/lazyTypingUtils";
import jaLocale from "date-fns/locale/ja";
import CommonButton from "../../common/Button";
import packagingSlipService from "../service/packagingSlipService";
import DownloadButton from "../../common/DownloadBtn";
import { API } from "../../../config";
import Cookies from "../../../utils/Cookies";



const PreparingForShipment = (props: any) => {
    const { showSnackbar, handleLoader } = props;
    const preparingShipmentFilter = useAppSelector((state: any) => state?.common?.backOrderFilter);
    const omsCurrentPage = useAppSelector((state: any) => state.common.preparingShipment);
    const perPageLimit = useAppSelector((state) => state.common.perPageLimit);
    const utility = new Utility;

    const firstPage = CONSTANTS.FIRST_PAGE;
    const defaultLimit = LIST_LIMIT.LIMIT_15;
    const dispatch = useAppDispatch();
    const [t, i18n] = useTranslation();
    const [skeleton, setSkeleton] = useState(true);
    const [currentPage, setCurrentPage] = useState<any>(omsCurrentPage?.currentPage ?? 1);
    const [totalCount, setTotalCount] = useState<any>(0);
    const [totalPages, setTotalPages] = useState<any>(0);
    // const [limit, setLimit] = useState(omsCurrentPage?.limit);
    const [limit, setLimit] = useState(!perPageLimit ? defaultLimit : perPageLimit);
    const [omsData, setOmsData] = useState<any>([]);
    const orderIdCompare: any[] = [];
    const productInvoiceNumber: any[] = [];
    const updatedAt: any[] = [];
    const [isInvitationSent, setIsInvitationSent] = useState(false);
    const [, forceUpdate] = useState(0);
    const [dateRang, setDateRang] = useState<any>(preparingShipmentFilter?.dateRang ? preparingShipmentFilter?.dateRang : 7);
    const [shipDate, setShipDate] = useState(preparingShipmentFilter?.shipDate ? preparingShipmentFilter?.shipDate : "ASC");
    const [search, setSearch] = useState<string>(preparingShipmentFilter?.search ? preparingShipmentFilter?.search : "");
    const [valueFrom, setValueFrom] = React.useState<Date | null>(preparingShipmentFilter?.valueFrom ? preparingShipmentFilter?.valueFrom : null);
    const [valueTo, setValueTo] = React.useState<Date | null>(preparingShipmentFilter?.valueTo ? preparingShipmentFilter?.valueTo : null);
    const [viewCustom, setViewCustom] = useState<boolean>(preparingShipmentFilter?.viewCustom ? preparingShipmentFilter?.viewCustom : false);
    const [fromParam, setFromParam] = useState<any>(preparingShipmentFilter?.fromParam ? preparingShipmentFilter?.fromParam : "");
    const [toParam, setToParam] = useState<any>(preparingShipmentFilter?.toParam ? preparingShipmentFilter?.toParam : "");
    const [filterChange, setFilterChange] = useState(false);
    const [statusCode, setStatusCode] = useState<any>(
        preparingShipmentFilter?.statusCode ? preparingShipmentFilter?.statusCode : ""
    );

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );

    useEffect(() => {
        const handlePageRefresh = () => {
            dispatch(commonActions.OMS_ORDER_FILTER_REMEMBER({}));
            dispatch(commonActions.IS_UNSHIPPED({ currentPage: 1, limit: 15 }));
            dispatch(commonActions.IS_SHIPPED({ currentPage: 1, limit: 15 }));
            dispatch(commonActions.IS_PREPARING_SHIPPMENT({ currentPage: 1, limit: 15 }));
            dispatch(commonActions.IS_CANCEL({ currentPage: 1, limit: 15 }));
            dispatch(commonActions.IS_REFUND({ currentPage: 1, limit: 15 }));
            dispatch(commonActions.IS_DOWNLOAD({ currentPage: 1, limit: 15 }));
        };
        window.addEventListener("beforeunload", handlePageRefresh);
        return () => {
            window.removeEventListener("beforeunload", handlePageRefresh);
        };
    }, []);

    useEffect(() => {
        forceUpdate(1);
    });

    const [checked, setChecked] = useState(new Array(limit).fill(false));
    const [disabled, setDisabled] = useState<boolean>(true);

    const handleCheckAllChange = (e: any) => {
        e.target.checked == true
            ? setChecked(new Array(i).fill(true))
            : setChecked(new Array(i).fill(false));
    };

    const handleCheckboxChange = (index: any) => (event: any) => {
        const newChecked = [...checked];
        newChecked[index] = event.target.checked;
        setChecked(newChecked);
    };


    const onSumbit = (checkList: any) => {
        const disabledCheck = checkDisabledStatus(checkList);
        if (disabledCheck.flag) {
            // alert(disabledCheck.messageVarData);
            showSnackbar(disabledCheck.messageVarData, false);
        } else {
            // Perform the action when the button is clicked after 5 minutes
            // ...
            const shippedID: any = [];
            for (let index = 0; index < checkList.length; index++) {
                if (checkList[index]) {
                    shippedID.push(orderIdCompare[index]);
                }
            }
            const orderId = shippedID.filter((item: any) => item != undefined);

            if (checked.filter((e) => e == true).length > 0) {
                dispatch(commonActions.SAVE_ORDER_ID(shippedID));
                navigate(ROUTES.SHIPPING_MULTIPLE_ORDER);
                // window.open(ROUTES.SHIPPING_MULTIPLE_ORDER, "_blank", "noreferrer");
            } else {
                alert("Please Select at least one order");
            }
        }
    };

    const navigate = useNavigate();
    useEffect(() => {
        omsPreparingShipmentData(currentPage, limit);
        totalPages != 0 ? setOmsData(currentPage) : null;
    }, []);


    useEffect(() => {
        if (isInvitationSent || dateRang || shipDate) {
            if (filterChange) {
                omsPreparingShipmentData(firstPage, limit);
            }
        }
    }, [isInvitationSent, dateRang, shipDate]);
    useEffect(() => {
        setChecked(new Array(limit).fill(false));

    }, [limit]);

    const onsearchApicall = () => {
        if (filterChange || search !== "") {
            omsPreparingShipmentData(firstPage, limit);
        }
    };

    const propItem = [
        onsearchApicall, search
    ];
    lazyTypingUtils(propItem);

    const omsStoreId = useAppSelector((state) => state.common.loggedInStoreId);

    const omsPreparingShipmentData = (currentPage: number, limit: number) => {
        setFilterChange(false);
        setSkeleton(true);
        const payload = {};
        const isHitDummyURL = false;
        new OmsManageOrderService(payload, isHitDummyURL)
            .omsPreparingShipmentList(
                currentPage,
                search.trim(),
                dateRang,
                toParam,
                fromParam,
                shipDate,
                OMS_CONSTANTS.STATUS_PREPARE_TO_SHIP,
                limit
            )
            .then((res) => {
                setOmsData(res?.omsLogs);
                if (res?.pagination?.totalCount >= 0) {
                    setSkeleton(false);
                }
                dispatch(commonActions.SAVE_ORDER_DATA(res?.omsLogs));
                setCurrentPage(currentPage);
                setTotalCount(res?.pagination?.totalCount);
                setTotalPages(res?.pagination?.totalPages);
                setLimit(limit);
                dispatch(commonActions.IS_PREPARING_SHIPPMENT({ currentPage: currentPage, limit: limit }));
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
            });
    };

    // Print Slip Code IS Removed 
    // const handleRefund = () => {
    //     navigate(ROUTES.REFUND_ORDER);
    // };

    // const handleSlip = (orderId: any) => {
    //     const orderIdData = [];
    //     orderIdData.push(orderId);
    //     dispatch(commonActions.SAVE_ORDER_ID(orderIdData));
    //     window.open(ROUTES.PRINT_PACKING_SLIP, "_blank", "noreferrer");
    // };


    // const handleAllSlip = (checkList: any) => {
    //     const orderId2: any = [];

    //     for (let index = 0; index < checkList.length; index++) {
    //         if (checkList[index]) {
    //             orderId2.push(orderIdCompare[index]);
    //         }
    //     }
    //     const orderId = orderId2.filter((item: any) => item != undefined);

    //     if (checked.filter((e) => e == true).length > 0) {
    //         dispatch(commonActions.SAVE_ORDER_ID(orderId2));
    //         window.open(ROUTES.PRINT_PACKING_SLIP, "_blank", "noreferrer");
    //     } else {
    //         alert("Please Select at least one order");
    //     }
    // };

    const handleSelectRange = (event: SelectChangeEvent) => {
        setFilterChange(true);
        if (event.target.value == "custom") {
            setViewCustom(true);
        } else {
            setViewCustom(false);
            setFromParam("");
            setToParam("");
            setValueFrom(null);
            setValueTo(null);
            setDateRang(event.target.value);
        }
    };

    const handleSelectShipDate = (event: SelectChangeEvent) => {
        setFilterChange(true);
        setShipDate(event.target.value);
    };

    const handleSearchChange = (e: any) => {
        setFilterChange(true);
        setChecked(new Array(checked.length).fill(false));
        const { value } = e.target;
        setSearch(value);
    };

    const params: any = useParams();

    const checkDisabledStatus = (checkList: any) => {
        let flag = false;
        let message = "";
        const currentTime = new Date();
        let updatedAtTime;
        const checkedDataupdatedAt: any = [];
        for (let index = 0; index < checkList.length; index++) {
            if (checkList[index]) {
                checkedDataupdatedAt.push(updatedAt[index]);
            }
        }

        checkedDataupdatedAt.map((updateDate: { sellerCancellableFlag: number, updatedAt: Date }) => {
            if (updateDate.sellerCancellableFlag == 1) {
                updatedAtTime = updateDate.updatedAt;
                const timeDifferenceInMinute = (currentTime.getTime() - updatedAtTime.getTime()) / (1000 * 60);
                const thresholdTimeInMinutes = 5;
                if (timeDifferenceInMinute <= thresholdTimeInMinutes) {

                    flag = true;

                    let timeDifferenceInMinute = (currentTime.getTime() - updatedAtTime.getTime()) / (1000 * 60);
                    if (timeDifferenceInMinute == 0) {
                        timeDifferenceInMinute = (currentTime.getTime() - updatedAtTime.getTime()) / (1000);
                    }

                    const minutesRemaining = Math.floor((5 - timeDifferenceInMinute));
                    const secondsRemaining = Math.floor(((5 - timeDifferenceInMinute) - minutesRemaining) * 60);

                    message = `出荷は ${minutesRemaining} 分 ${secondsRemaining} 秒後に行うことができます`;
                    return false;
                }
            }
        });


        return {
            flag: flag,
            messageVarData: message
        };
    };

    const checkDisabledSing = (updatedAt: any, sellerCancellableFlag: any) => {

        let flag = false;
        let message = "";
        if (sellerCancellableFlag == 1) {
            const currentTime = new Date();
            const updatedAtTime = new Date(updatedAt);
            let timeDifferenceInMinutes = (currentTime.getTime() - updatedAtTime.getTime()) / (1000 * 60);

            const thresholdTimeInMinutes = 5;

            if (timeDifferenceInMinutes <= thresholdTimeInMinutes) {
                flag = true;
            }

            if (timeDifferenceInMinutes == 0) {
                timeDifferenceInMinutes = (currentTime.getTime() - updatedAtTime.getTime()) / (1000);
            }

            const minutesRemaining = Math.floor((5 - timeDifferenceInMinutes));
            const secondsRemaining = Math.floor(((5 - timeDifferenceInMinutes) - minutesRemaining) * 60);

            message = `${t("oms.order_can_be_shipped")} ${minutesRemaining} ${t("oms.minutes")} ${secondsRemaining} ${t("oms.secound")}`;

            // message = "order can be shipped after" + (5 - timeDifferenceInMinutes) + " minutes ";
        }
        return {
            flag: flag,
            messageVar: message
        };
    };


    const handleEditShipment = (id: number) => {
        // const disabledSingle = checkDisabledSing(updatedAt,sellerCancellableFlag);
        // if (disabledSingle.flag) {
        //     alert(disabledSingle.messageVar);
        // } else {

        // }
        navigate(`${ROUTES.OMS_CONFIRM_SHIPMENT}/${id}`);
    };


    const handleShipment = (id: number, updatedAt: any, sellerCancellableFlag: any, showModels = PARAMS_CONSTANTS.STATUS_TWO) => {

        const disabledSingle = checkDisabledSing(updatedAt, sellerCancellableFlag);

        if (disabledSingle.flag) {
            // alert(disabledSingle.messageVar);
            showSnackbar(disabledSingle.messageVar, false);

        } else {

            navigate(`${ROUTES.OMS_CONFIRM_SHIPMENT}/${id}?showModel=${showModels}`);
        }
    };

    const handlelCancel = (id: number, showModels = PARAMS_CONSTANTS.STATUS_ONE) => {
        navigate(`${ROUTES.OMS_CONFIRM_SHIPMENT}/${id}?showModel=${showModels}`);
    };

    const handleDateTo = (value: any) => {
        const [day, month, year] = value.toLocaleDateString("en-GB").split("/");
        setToParam(`${year}/${month}/${day}`);
        setValueTo(value);
    };
    const handleDateFrom = (value: any) => {
        const [day, month, year] = value.toLocaleDateString("en-GB").split("/");
        setFromParam(`${year}/${month}/${day}`);
        setValueFrom(value);
    };
    const handleSubmit = () => {
        if (validator.current.allValid()) {
            setDateRang("");
            if (dateRang == "") {
                const data = {
                    search,
                    shipDate,
                    dateRang,
                    fromParam,
                    toParam,
                    viewCustom,
                    valueFrom,
                    valueTo,
                    statusCode
                };
                dispatch(commonActions.OMS_ORDER_FILTER_REMEMBER(data));
                omsPreparingShipmentData(currentPage, limit);
            }
        }
        else {
            validator.current.showMessages();
        }
    };
    useEffect(() => {
        const data = {
            search,
            shipDate,
            dateRang,
            fromParam,
            toParam,
            viewCustom,
            valueFrom,
            valueTo,
            statusCode
        };
        dispatch(commonActions.OMS_ORDER_FILTER_REMEMBER(data));
    }, [search, shipDate, dateRang]);


    const resetAllFilter = () => {
        setFilterChange(true);
        setViewCustom(false);
        setFromParam("");
        setToParam("");
        setValueFrom(null);
        setValueTo(null);
        setDateRang("7");
        setShipDate("ASC");
        setSearch("");
    };
    const shouldDisableDateTo = (date: any) => {
        if (valueFrom) {
            return date < valueFrom;
        }
        return false;
    };
    const shouldDisableDateFrom = (date: any) => {
        if (valueTo) {
            return date > valueTo;
        }
        return false;
    };

    let i = 0;

    const singleOrderPackingSlip = async(id:any) => {
        handleLoader(true);
        await new packagingSlipService().refreshAccessToken();
        const selectedOrders = orderIdCompare.filter((item: any) => item === id).toString();
        const screenWidth = window.screen.width;
        const payload = {};
        const isHitDummyUrl = false;
        new packagingSlipService(payload, isHitDummyUrl)
            .packingSlip(
                selectedOrders
            )
            .then(async (res: any) => {
                const pdfWindow = window.open(res, "", `width=1200,height=1000,left=${screenWidth - 1000}`);
                pdfWindow?.document.close();
                pdfWindow?.focus();
                setTimeout(() => {
                    pdfWindow?.print();
                }, 2000);
                handleLoader(false);
            }).catch((err) => {
                showSnackbar(err?.message, false);
                handleLoader(false);
            });
    };


    const multiOrderPackingSlip = async() => {
        handleLoader(true);
        await new packagingSlipService().refreshAccessToken();
        const selectedOrders = checked.map((item: any, index: number) => checked[index] && orderIdCompare[index]).filter((item: any) => item !== false).toString();
        const screenWidth = window.screen.width;
        const payload = {};
        const isHitDummyUrl = false;
        new packagingSlipService(payload, isHitDummyUrl)
            .packingSlip(
                selectedOrders,
                limit
            )
            .then(async (res: any) => {
                const pdfWindow = window.open(res, "", `width=1200,height=1000,left=${screenWidth - 1000}`);
                pdfWindow?.document.close();
                pdfWindow?.focus();
                setTimeout(() => {
                    pdfWindow?.print();
                }, 2000);
                handleLoader(false);
            }).catch((err) => {
                showSnackbar(err?.message, false);
                handleLoader(false);
            });
    };


    return (
        <>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>

                        <Box className="oms_unshipped_filter">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={4} lg={3} sx={{ py: 1, px: 2 }}>
                                    <Typography className="oms-shipped-text">
                                        <Box component="span" className="oms_text_margin">{totalCount}</Box> {t("oms.order_in_last")} {dateRang} {t("oms.days")}
                                    </Typography>

                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <TextField
                                        // id="input-with-icon-textfield"
                                        label={t("oms.search_by_order_id")}
                                        onChange={handleSearchChange}
                                        // onKeyUp={handleKeyPress}
                                        value={search}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={5} md={4} lg={2}>
                                    <FormControl size="small" fullWidth>
                                        <InputLabel id="demo-simple-select-helper-label">
                                            {t("oms.order_confirmation_date")}
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            className="oms-tab-filters"
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={viewCustom ? "custom" : dateRang}
                                            label={t("oms.order_confirmation_date")}
                                            onChange={handleSelectRange}
                                        >
                                            <MenuItem value={7}>{t("oms.last_7_days")}</MenuItem>
                                            <MenuItem value={30}>{t("oms.last_30_days")}</MenuItem>
                                            <MenuItem value={90}>{t("oms.Last_90_days")}</MenuItem>
                                            <MenuItem value={365}>{t("oms.last_year")}</MenuItem>
                                            <MenuItem value={"custom"}>{t("oms.custom_date_range")}</MenuItem>

                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} lg={2}>
                                    <Box>
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="demo-simple-select-helper-label">
                                                {t("oms.order_date")}
                                            </InputLabel>
                                            <Select
                                                fullWidth
                                                className="oms-tab-filters"
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={shipDate}
                                                label={t("oms.order_date")}
                                                onChange={handleSelectShipDate}
                                            >
                                                <MenuItem value="DESC">{t("oms.descending")}</MenuItem>
                                                <MenuItem value="ASC">{t("oms.ascending")}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={3} md={2} lg={2} className="reset_btn">
                                    <Button
                                        variant="contained"
                                        sx={{ textTransform: "capitalize" }}
                                        onClick={resetAllFilter}
                                        className="oms_submit-btn"
                                    >
                                        {t("pr.reset")}
                                    </Button>
                                </Grid>
                                {
                                    viewCustom ? (
                                        <>
                                            <Grid item xs={0} sm={0} md={1} lg={6} ></Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={6} className="oms-date-picker">
                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={jaLocale} dateFormats={{ monthAndYear: "yyyy年MM月" }}>
                                                    <Grid className="oms_date_picker_grid">
                                                        <DatePicker
                                                            // className="date-picker-default"
                                                            label={t("pr.from")}
                                                            value={valueFrom}
                                                            inputFormat="yyyy/MM/dd"
                                                            onChange={handleDateFrom}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        readOnly: true,
                                                                        style: { pointerEvents: "none" },
                                                                    }}

                                                                />)}
                                                            autoFocus={true}
                                                            disableFuture
                                                            shouldDisableDate={shouldDisableDateFrom}
                                                            // maxDate={valueTo}  
                                                            showToolbar={false}
                                                            componentsProps={{
                                                                actionBar: {
                                                                    // The actions will be the same between desktop and mobile
                                                                    actions: []
                                                                }
                                                            }}

                                                        />
                                                        {validator.current.message("from", valueFrom, [
                                                            "required",
                                                        ])}

                                                    </Grid>

                                                </LocalizationProvider>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={jaLocale} dateFormats={{ monthAndYear: "yyyy年MM月" }}>
                                                    <Grid className="oms_date_picker_grid">
                                                        <DatePicker
                                                            className="oms-date-picker-default"
                                                            label={t("pr.to")}
                                                            value={valueTo}
                                                            inputFormat="yyyy/MM/dd"
                                                            onChange={handleDateTo}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        readOnly: true,
                                                                        style: { pointerEvents: "none" },
                                                                    }}

                                                                />)}
                                                            autoFocus={true}
                                                            disableFuture
                                                            // minDate={valueFrom}
                                                            shouldDisableDate={shouldDisableDateTo}
                                                            showToolbar={false}
                                                            componentsProps={{
                                                                actionBar: {
                                                                    actions: []
                                                                }
                                                            }}
                                                        />
                                                        {validator.current.message("to", valueTo, [
                                                            "required",
                                                        ])}

                                                    </Grid>
                                                </LocalizationProvider>
                                                <Button
                                                    variant="contained"
                                                    className="oms_submit-btn"
                                                    sx={{ textTransform: "capitalize" }}
                                                    onClick={handleSubmit}
                                                >
                                                    {t("pr.submit")}
                                                </Button>
                                            </Grid>
                                        </>
                                    ) : null
                                }
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <Container className="oms_table">
                <Grid container spacing={2}>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        {skeleton ? (
                            <Skeleton
                                varient="rectangular"
                                width={0}
                                height={253}
                                count="10"
                            />
                        ) : (
                            <TableContainer component={Paper}>
                                <Box sx={{ display: "flex" }}>
                                    <Box>
                                        <DownloadButton route={window.location.pathname} functionToExecute={()=>multiOrderPackingSlip()} className="oms_head_btn margin_vertical" btnName={t("packaging_slip.print_the_delivery_note")} disableCondition={checked.filter((e) => e == true).length > 0 ? false : true} />
                                    </Box>
                                    <CommonButton sx={{ ml: 2 }} route={window.location.pathname} functionToExecute={() => onSumbit(checked)} className="oms_head_btn margin_vertical" btnName={t("oms.confirm_shipments")} disableCondition={checked.filter((e) => e == true).length > 0 ? false : true} />

                                </Box>

                                <Table aria-label="simple table">
                                    <TableHead className="oms_head">
                                        <TableRow>
                                            <TableCell>
                                                <Box>
                                                    <Checkbox
                                                        className="oms_head_checkbox"
                                                        checked={
                                                            checked.filter((e) => e == false).length > 0
                                                                ? false
                                                                : true
                                                        }
                                                        disabled={
                                                            omsData?.length == 0
                                                        }
                                                        onClick={(e) => handleCheckAllChange(e)}
                                                    />

                                                    {t("oms.date")}
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>{t("oms.order_details")}</Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>{t("oms.product_information")} </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>{t("oms.customer_option")}</Box>
                                            </TableCell>
                                            <TableCell className="order_status">
                                                <Box>{t("oms.order_status")}</Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box>{t("oms.action")}</Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {omsData
                                            // .filter((e: any) => !orderIdCompare.includes(e.orderDateTime))
                                            ?.map((item: any, idIndex: number) => {
                                                const products = item?.products;
                                                // checkDisabledStatus(item?.updatedAt);
                                                orderIdCompare.push(item?.orderId);
                                                updatedAt.push(
                                                    {
                                                        updatedAt: new Date(item?.updatedAt),
                                                        sellerCancellableFlag: item.sellerCancelableFlag
                                                    });
                                                // updatedAt.push(item, "item");
                                                return (
                                                    <TableRow key={idIndex}>
                                                        {/* {!orderIdCompare.includes(item.orderId) ? ( */}
                                                        <TableCell>
                                                            <Box className="oms_table_box">
                                                                <Typography
                                                                    className={"order_date_checkbox " + idIndex}
                                                                >
                                                                    <Checkbox
                                                                        checked={checked[i]}
                                                                        onChange={handleCheckboxChange(i++)}
                                                                    />
                                                                    {/* {<Checkbox checked={checkAll} onClick={(e)=>handleChange(e)}/> } */}

                                                                    <Box sx={{display: "inline-grid"}}>
                                                                        <Typography>
                                                                            {t("oms.order_date")} :
                                                                        </Typography>
                                                                        <Typography>
                                                                            {item.orderDate}
                                                                        </Typography>
                                                                        <Typography>
                                                                            {t("oms.order_confirmation_date")} :
                                                                        </Typography>
                                                                        <Typography>
                                                                            {item.orderConfirmationDate}
                                                                        </Typography>
                                                                    </Box>
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                        {/* ) : null} */}
                                                        {/* {omsData
                                                            .filter(
                                                                (e: any) =>
                                                                // e.merchantCode == omsStoreId &&
                                                                    e.orderId == item.orderId &&
                                  // e.merchantCode == item.merchantCode &&
                                  !orderIdCompare.includes(e.orderId)
                                                            )
                                                            ?.map((store: any, storeIndex: number) => {
                                                                orderIdCompare.push(item.orderId);
                                                                return storeIndex == 0 ? ( */}
                                                        <TableCell colSpan={5}>
                                                            <Table>
                                                                <TableBody>
                                                                    <TableRow className="CEC-border-none">
                                                                        <TableCell>
                                                                            <Box className="oms_table_box">
                                                                                <Typography>{t("oms.order_id")} : </Typography>

                                                                                <Typography className="text_wrap_data">
                                                                                    <Link to={`${ROUTES.OMS_CONFIRM_SHIPMENT}/${item?.orderId}`} className="Order_id_link">
                                                                                        {item?.orderId}
                                                                                    </Link>
                                                                                </Typography>
                                                                                <Typography>{t("oms.buyer_name")} : </Typography>
                                                                                <Typography className="text_wrap_data">
                                                                                    {item?.ordererName} {item?.nameOfOrderer}
                                                                                </Typography>
                                                                            </Box>
                                                                        </TableCell>

                                                                        <TableCell>


                                                                            <Table>
                                                                                <TableBody>

                                                                                    {products?.map(
                                                                                        (i: any, productIndex: any) => {
                                                                                            i?.invoiceNumber ? productInvoiceNumber.push(i?.invoiceNumber) : null;
                                                                                            return (

                                                                                                <TableRow
                                                                                                    key={productIndex}
                                                                                                    className="table_border"
                                                                                                >

                                                                                                    <TableCell style={{ padding: "10px 0px" }}>
                                                                                                        <Box className="oms_table_box">
                                                                                                            <img
                                                                                                                src={
                                                                                                                    i?.image
                                                                                                                }
                                                                                                                alt=""
                                                                                                            />
                                                                                                            <Typography className="text_wrap_data">
                                                                                                                {i?.productName}
                                                                                                            </Typography>

                                                                                                            <Typography>
                                                                                                                {t("oms.cpid")} : {i?.cainzProductCode}
                                                                                                            </Typography>

                                                                                                            <Typography>
                                                                                                                {t("oms.quantity")} : {i?.salesVolume}
                                                                                                            </Typography>

                                                                                                            <Typography>
                                                                                                                {t("oms.shipped_quantity")} : {i?.shippedQuantity.split("|").filter((item: any) => item !== "0").length > 0 ? i?.shippedQuantity.split("|").filter((item: any) => item !== "0").join(",") : "0"}
                                                                                                            </Typography>

                                                                                                            <Typography className="text_wrap_data">
                                                                                                                {t("oms.price_subtotal")} : &yen;{new Utility().numberWithCommas(i?.productPriceIncludingTaxSubtotal)}
                                                                                                            </Typography>
                                                                                                            {
                                                                                                                productIndex + 1 == products?.length ?
                                                                                                                    "" :
                                                                                                                    <Divider sx={{ mt: 3 }} />
                                                                                                            }

                                                                                                        </Box>
                                                                                                    </TableCell>

                                                                                                    <TableCell>
                                                                                                        <Box className="oms_table_box">
                                                                                                            <Typography>{t("oms.delivery_by_date")} : </Typography>
                                                                                                            <Typography className="text_wrap_data">
                                                                                                                {i?.productDeliveryDate}
                                                                                                            </Typography>
                                                                                                        </Box>
                                                                                                    </TableCell>
                                                                                                </TableRow>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                    {/* );
                                                                                            }
                                                                                        )} */}
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableCell>

                                                                        <TableCell>
                                                                            <Button
                                                                                className="unshipped order_status"
                                                                                variant="contained" >
                                                                                {t("oms.preparing_for_shipment")}
                                                                            </Button>
                                                                        </TableCell>

                                                                        <TableCell>
                                                                            <Box className="table_button">
                                                                                <CommonButton route={window.location.pathname} functionToExecute={() => handleEditShipment(item?.orderId)} className="unshipped" btnName={t("oms.edit_shipment")} disableCondition={false} />

                                                                                {/* <Button
                                                                                    className="unshipped"
                                                                                    variant="contained"
                                                                                    onClick={() =>
                                                                                        handleEditShipment(item?.orderId)
                                                                                    }
                                                                                >
                                                                                    {t("oms.edit_shipment")}
                                                                                </Button> */}
                                                                                {/* <DownloadButton route={window.location.pathname} functionToExecute={() => singleOrderPackgingSlip(item?.orderId)} className="unshipped" btnName={t("packaging_slip.print_the_delivery_note")} /> */}
                                                                                <DownloadButton route={window.location.pathname} functionToExecute={()=>singleOrderPackingSlip(item?.orderId)} className="unshipped" btnName={t("packaging_slip.print_the_delivery_note")} />

                                                                                <CommonButton route={window.location.pathname} functionToExecute={() => handleShipment(item?.orderId, item?.updatedAt, item?.sellerCancelableFlag)} className="unshipped" btnName={t("oms.confirm_shipment")} disableCondition={false} />


                                                                                {/* <Button
                                                                                    className="unshipped"
                                                                                    variant="contained"
                                                                                    onClick={() =>
                                                                                        handleShipment(item?.orderId, item?.updatedAt, item?.sellerCancelableFlag)
                                                                                    }
                                                                                >
                                                                                    {t("oms.confirm_shipment")}
                                                                                </Button> */}
                                                                                {/* <Button
                                                                                    onClick={() =>
                                                                                        handleSlip(item?.orderId)
                                                                                    }
                                                                                    className="unshipped packing_slip_btn"
                                                                                    variant="contained"
                                                                                >
                                                                                    {t("oms.print_packing_slips")}
                                                                                </Button> */}



                                                                                {
                                                                                    item.sellerCancelableFlag == 0 || item?.cancelFlag == false ? null :
                                                                                        <CommonButton route={window.location.pathname} functionToExecute={() => handlelCancel(item?.orderId)} className="unshipped background_color_red" btnName={t("oms.cancel_order")} disableCondition={false} />

                                                                                    // <Button
                                                                                    //     className="unshipped"
                                                                                    //     variant="contained" 
                                                                                    //     color="error" 
                                                                                    //     onClick={() =>
                                                                                    //         handlelCancel(item?.orderId)
                                                                                    //     }> {t("oms.cancel_order")} </Button>       
                                                                                }
                                                                            </Box>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </TableCell>
                                                        {/* ) : null;
                                                            })} */}
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                        <Typography align="center" variant="h6">
                            {omsData?.length == 0 ? t("oms.no_order_found") : ""}
                        </Typography>
                    </Grid>
                </Grid>
                <Pagination
                    totalCount={totalCount}
                    currentPage={currentPage}
                    pageChange={omsPreparingShipmentData}
                    totalPage={totalPages}
                    rowsPerPage={limit}
                    rowsPerPageChange={setLimit}
                />
            </Container>
        </>
    );
};

export default PreparingForShipment;