import React, {useState} from "react";
import {
    Container,
    Grid,
    Box,
    Accordion,
    AccordionSummary,
    Typography,
    IconButton,
    Button,
} from "@mui/material";

import CircularProgressBar from "../CircularProgressBar";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearIcon from "@mui/icons-material/Clear";
import { useAppSelector } from "../../../redux/hooks";
import { useDispatch } from "react-redux";
import { commonActions } from "../../../redux/features/common/commonSlice";
import { t } from "i18next";

const UploadBox = () => {

    const [reload,setReload] = useState(false);
    const dispatch = useDispatch();
    const fileName = useAppSelector((state) => state.common.isFileNameStored) ?? "";
    const bulkProductUploadProgress =useAppSelector((state)=>state.common.bulkProductUploadProgress) ?? 0;

    const progressbarMessage = useAppSelector((state) => state.common.isProgressbarMessage) ?? 0; 

    const isOpenProgressbar = useAppSelector((state) => state.common.isOpenProgressbar) ?? false;

    const cancelButton = () => {
        dispatch(commonActions.SAVE_OPEN_PROGRESSBAR(false));
        dispatch(commonActions.PROGRESSBAR_MESSAGE(0));
        dispatch(commonActions.SAVE_FILE_NAME(""));
        setReload(false);
    };

    return (
        <>
            <Container className="upload_box">
                <Grid container spacing={2}>
                    <Grid item xl={8} lg={8} md={9} sm={6} xs={12}></Grid>
                    {isOpenProgressbar || reload? (
                        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                            <Box className="upload_file_box">
                                <Accordion className="file_upload_box" expanded={true}>
                                    <AccordionSummary
                                        aria-controls="panel1d-content"
                                        id="panel1a-header"
                                    >
                                        { progressbarMessage === 0 ? (
                                            <Box>
                                                <Typography sx={{ fontWeight:"bold" }}>
                                                    {t("bulkproductupload.uploading")}
                                                </Typography>
                                                {fileName}
                                                <CircularProgressBar />
                                                <Typography className="progressPercent">{bulkProductUploadProgress}%</Typography>                                                                           
                                           
                                            </Box>
                                        ) : progressbarMessage === 1 ? (
                                        
                                            <Box>
                                                <Typography sx={{ fontWeight: "bold" }}> {t("bulkproductupload.done")}</Typography>
                                                {fileName}
                                                <CheckCircleIcon className="success_icon" />

                                                <Button
                                                    aria-label="clearIcon"
                                                    color="primary"
                                                    className="upload_cancel"
                                                    onClick={cancelButton}
                                                >
                                                    <ClearIcon />
                                                </Button>
                                            </Box>
                                        ) : (
                                            <Box>
                                                <Typography sx={{ fontWeight: "bold" }}> {t("bulkproductupload.error")}</Typography>
                                                {fileName}
                                                <CancelIcon className="cancel_icon" />
                                                <Typography>
                                                    <IconButton
                                                        aria-label="clearIcon"
                                                        color="primary"
                                                        className="upload_cancel"
                                                        onClick={cancelButton}
                                                    >
                                                        <ClearIcon/>
                                                    </IconButton>
                                                </Typography>
                                            </Box>
                                        )}
                                    </AccordionSummary>
                                </Accordion>
                            </Box>
                        </Grid>
                    ) : null}
                </Grid>
            </Container>
        </>
    );
};

export default UploadBox;
