interface paginationRedux {
    currentPage: number | null,
    limit: number | null
}

interface CommonType {
    isStoreCreated?: boolean | null;
    loggedInUserEmail?: string;
    loggedInUserName?: string;
    loggedInAdminUserName?: string;
    loggedInStoreId?: number | null;
    loggedInStoreName?: string | null;
    loggedInStoreCode?: number | null;
    isOrderIdStored?: any | null;
    isOrderDataStored?: any | null;
    isFileNameStored?: string;
    isFileNameStoredBulk?: string;
    isFileNameStoredInventory?: string;
    isOpenProgressbar?: any | any;
    isOpenProgressbarInventory?: any | any;
    isOpenProgressbarBulkImageUpload?: any | any;
    isProgressbarMessage?: number;
    isProgressbarMessageInventory?: number;
    bulkImageUploadProgress?: number;
    bulkProductUploadProgress?: number
    isProgressbarMessageBulkImageUpload?: number;
    isReloadPage: boolean;
    isTabUrl: string;
    productData: any;
    productDataJson: any;
    backOrderFilter: any;
    refundOrderFilter: any;
    loggedInUserType?: number | null;
    isMaintenance?: string;
    perPageLimit?: number | null;
    newPermission: any;
    imagePagination?: {
        pagintaion: any,
        count: number,
        productId: string
    };
    unshipped: paginationRedux | null,
    shipped: paginationRedux | null,
    preparingShipment: paginationRedux | null,
    refundedOrders: paginationRedux | null,
    cancelled: paginationRedux | null,
    orderStatus: paginationRedux | null,
    productList: paginationRedux | null,
    productListFilter?: {
        search: any,
        flag: any,
        productCode: any,
        individualShippingFee: any,
        colFilter:any,
        direction:any
    };
    imageListFilter?: {
        flag: any,
        imageStatus: any
    }
    hideColumnFilter: any;
    dialogBoxTimerValue: any;
    timerValue: any;
    rememberImageUploadParams?:{
        pageNumber:any,
        search:any,
        colFilter:any,
        colName:any,
        direction:any
    },
    selectedProduct:any
}

export const commonInitialValue: CommonType = {
    isStoreCreated: null,
    loggedInUserEmail: "",
    loggedInUserName: "",
    loggedInAdminUserName: "",
    loggedInStoreId: null,
    loggedInStoreName: "",
    loggedInStoreCode: null,
    isOrderIdStored: null,
    isOrderDataStored: null,
    isFileNameStored: "",
    isFileNameStoredBulk: "",
    isFileNameStoredInventory: "",
    isOpenProgressbar: false,
    isOpenProgressbarInventory: false,
    isOpenProgressbarBulkImageUpload: false,
    isProgressbarMessage: 0,
    isProgressbarMessageInventory: 0,
    bulkImageUploadProgress: 0,
    bulkProductUploadProgress: 0,
    isProgressbarMessageBulkImageUpload: 0,
    isReloadPage: false,
    loggedInUserType: null,
    newPermission: null,
    isTabUrl: "1",
    productData: [],
    productDataJson: [],
    backOrderFilter: {},
    perPageLimit: null,
    refundOrderFilter: {},
    isMaintenance: "false",
    dialogBoxTimerValue: null,
    imagePagination: {
        pagintaion: 1,
        count: 0,
        productId: ""
    },
    unshipped: {
        currentPage: 1,
        limit: 15
    },
    shipped: {
        currentPage: 1,
        limit: 15
    },
    preparingShipment: {
        currentPage: 1,
        limit: 15
    },
    refundedOrders: {
        currentPage: 1,
        limit: 15
    },
    cancelled: {
        currentPage: 1,
        limit: 15
    },
    orderStatus: {
        currentPage: 1,
        limit: 15
    },
    productList: {
        currentPage: 1,
        limit: 15
    },
    productListFilter: {
        search: "",
        flag: "none",
        productCode: "none",
        individualShippingFee: "none",
        colFilter:"",
        direction:""
    },
    imageListFilter: {
        flag: "none",
        imageStatus: "none"
    },
    hideColumnFilter: [],
    timerValue: null,
    rememberImageUploadParams:{
        pageNumber:7,
        search:"",
        colFilter:"",
        colName:"",
        direction:""
    },
    selectedProduct:""
};

export default CommonType;
