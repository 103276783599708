import { Container, Typography } from "@mui/material";
import React, { useState } from "react";
import HeaderTitle from "../../common/HeaderTitle";
import { useTranslation } from "react-i18next";
import ProfileService from "../services/ProfileService";
import RichTextEditor from "../../common/RichTextEditor";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { userActions } from "../../../redux/features/user/userSlice";
import { SnackbarAlert } from "../../common/SnackbarAlert";

const AboutUs = (props: any) => {

    const { showSnackbar,handleLoader } = props;
    const dispatch = useAppDispatch();

    const { t } = useTranslation();
    const about = useAppSelector((state) => state.user.userDetails?.store?.[0].policy?.aboutUs) ?? null;

    const [alertMessage, setAlertMessage] = useState<string>();
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const success = false;

    // const setMessage = (msg: string) => {
    //     setAlertMessage(msg);
    //     setOpenSnackBar(true);
    // };

    const saveAbout = (data: string) => {
        handleLoader(true);
        dispatch(userActions.SAVE_ABOUT_US(data));
        const payload = { aboutUs: data };
        const isHitDummyURL = false;
        new ProfileService(payload, isHitDummyURL).sellerBussinessInfo().then(res => {
            handleLoader(false);
            showSnackbar(res, true);
        }).catch(err => {     
            handleLoader(false),
            showSnackbar(err?.message, false);
        });

    };

    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
    };

    return (
        <>
            {/* Page header title */}
            <div className="main">
                <HeaderTitle title={t("profile.about_us")} />

                {/* Page header title */}

                <Container>
                    <Typography variant="h6" sx={{ my: 2 }}>
                        {t("profile.about_us")}
                    </Typography>
                    <RichTextEditor data={about} saveData={saveAbout} />
                    <SnackbarAlert
                        openSnackBar={openSnackBar}
                        message={alertMessage}
                        success={success}
                        handleCloseSnackBar={handleCloseSnackBar}
                    />
                </Container>
            </div>
        </>
    );
};

export default AboutUs;
