import React, { useEffect, useState, useRef } from "react";
import { Container, Grid, Button, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import CONSTANTS from "../../../constants/constants";
import HeaderTitle from "../../common/HeaderTitle";
import { useNavigate } from "react-router";
import ExhibitionUserListService from "../service/ExhibitionUserListService";
import { useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { ROUTES } from "../../../constants/routes";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { commonActions } from "../../../redux/features/common/commonSlice";

const ExhibitionUsersDetails = (props: any) => {
    const { handleLoader, showSnackbar } = props;
    const dispatch = useAppDispatch();
    // useEffect(() => {
    //     exhibitionUserDetail();
    // }, []);

    const [t] = useTranslation();
    const [name, setName] = useState<any>();
    const [email, setEmail] = useState<string>();
    const password = CONSTANTS.PASSWORD_TEXT;
    // const [isReload, setIsReload] = useState(false);
    // const [isDisableTextField, setIsDisableTextfield] = useState<boolean>(true);
    // const [isShowButtons, setIsShowButtons] = useState<boolean>(false);

    const loggedInUserEmail = useAppSelector((state) => state.common.loggedInUserEmail) ?? "";
    const loggedInUserName = useAppSelector((state) => state.common.loggedInUserName) ?? "";
    const params = useParams();

    useEffect(() => {
        params.id ? exhibitionUserDetail() : (
            setName(loggedInUserName),
            setEmail(loggedInUserEmail)
        );
    }, []);
    const exhibitorId = params.id;

    const exhibitionUserDetail = async () => {
        const payload = {};
        const isHitDummyURL = false;
        new ExhibitionUserListService(payload, isHitDummyURL)
            .showExhibitionUserDetail(parseInt(exhibitorId!))
            .then((res) => {
                const { name, email } = res;
                // loggedInUserEmail == email
                //     ? (setIsDisableTextfield(false), setIsShowButtons(true))
                //     : null;
                setName(name);
                setEmail(email);
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
            });
    };
    const navigate = useNavigate();
    const navigateDetailPage = () => {
        navigate(ROUTES.VERIFY_PASSWORD);
    };
    const navigateDetailPages = () => {
        navigate(ROUTES.EMAIL_UPDATE);
    };

    // validation
    const [, forceUpdate] = useState(0);

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                max: t("required.max_200_char"),
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );

    useEffect(() => {
        forceUpdate(1);
    });
    //validation

    const handleChange = (e: any) => {
        const { value, name } = e.target;
        name === "name"
            ? setName(value)
            : // : name === "email"
        //     ? setEmail(value)
        //     : name === "password"
        //         ? setPassword(value)
            null;
    };

    const adminNameUpdate = () => {
        if (validator.current.allValid()) {
            const payload = { name };
            const isHitDummyURL = false;
            new ExhibitionUserListService(payload, isHitDummyURL)
                .updateAdminName()
                .then((res) => {
                    handleLoader(false);
                    setName(name);
                    dispatch(commonActions.SAVE_LOGIN_USER_NAME(name));
                    showSnackbar(res.message, true);
                })
                .catch((err) => {
                    handleLoader(false);
                    showSnackbar(err?.message, false);
                });
        }else {
            validator.current.showMessages();
        }
    };

    return (
        <div className="main">
            <Container>
                <Grid container spacing={0} sx={{ py: 5 }}>
                    <Grid item xs={7} sm={5} md={5} lg={5} sx={{ py: 2 }}>
                        <HeaderTitle title={t("exhibition_user_list.personal_profile")} />
                    </Grid>
                </Grid>
                <Grid container spacing={0} sx={{ py: 5 }}>
                    <Grid item xs={12} sm={2} md={2} lg={4}></Grid>
                    <Grid item xs={8} sm={6} md={4} lg={3}>
                        <TextField
                            fullWidth
                            disabled={loggedInUserEmail == email ? false : true}
                            type="text"
                            name="name"
                            label={t("exhibition_user_list.user_name")}
                            variant="outlined"
                            onChange={(e) => handleChange(e)}
                            value={name ?? ""}
                        />
                        {validator.current.message("name", name, [
                            "required",
                            { max: 200 },
                        ])}
                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={1}>
                        {!params.id || loggedInUserEmail == email ? (
                            <Button fullWidth className="btn_custom_inline" onClick={adminNameUpdate}>
                                {t("exhibition_user_list.save")}
                            </Button>
                        ) : null}
                    </Grid>
                    <Grid item xs={12} sm={2} md={3} lg={4}></Grid>
                </Grid>
          
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={2} md={2} lg={4}></Grid>
                    <Grid item xs={8} sm={6} md={4} lg={3}>
                        <TextField
                            fullWidth
                            // inputRef={(input) => input && input.focus()}
                            disabled
                            type="text"
                            name="email"
                            label={t("exhibition_user_list.email_address")}
                            variant="outlined"
                            // onChange={(e) => handleChange(e)}
                            value={email ?? ""}
                        />
                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={1}>
                        {!params.id || loggedInUserEmail == email ? (
                            <Button
                                fullWidth
                                className="btn_custom_inline"
                                onClick={navigateDetailPages}
                            >
                                {t("exhibition_user_list.update")}
                            </Button>
                        ) : null}
                    </Grid>
                    <Grid item xs={12} sm={2} md={3} lg={4}></Grid>
                </Grid>
                <Grid container spacing={0} sx={{ py: 5 }}>
                    <Grid item xs={12} sm={2} md={2} lg={4}></Grid>
                    <Grid container xs={8} sm={6} md={4} lg={3}>
                        <TextField
                            fullWidth
                            // inputRef={(input) => input && input.focus()}
                            type="password"
                            disabled
                            name="password"
                            label={t("exhibition_user_list.password")}
                            variant="outlined"
                            // onChange={(e) => handleChange(e)}
                            value={password ?? ""}
                        />
                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={1}>
                        {!params.id || loggedInUserEmail == email ? (
                            <Button
                                fullWidth
                                className="btn_custom_inline"
                                onClick={() => navigateDetailPage()}
                            >
                                {t("exhibition_user_list.update")}
                            </Button>
                        ) : null}
                    </Grid>
                    <Grid item xs={12} sm={2} md={3} lg={4}></Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default ExhibitionUsersDetails;


