import React from "react";
import { Container, Grid, Box, Typography } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import TemplateDownloadcsv from "../services/templateDownloadcsv";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";
import CONSTANTS, { ROLE_MANAGEMENT_SYSTEM } from "../../../constants/constants";
import { newPermissionInitialValue } from "../../../redux/features/newRoleBasedPermission/newPermissionSlice";

const TemplateDownload = (props:any) => {

    const {handleLoader, showSnackbar} = props; 

    const { t } = useTranslation();

    const permission_list = useAppSelector((state) => state.newPermission.newPermissionDetails.modules?.seller) ?? newPermissionInitialValue.newPermissionDetails.modules?.seller;
    const loggedInUserType = useAppSelector(state => state.permission.permissionDetails.userType) ?? null;
    const storeCode = useAppSelector((state) => state?.common?.loggedInStoreCode) ?? null;
    const productManagementPermission = permission_list?.find((item :any) => item.key === "merchandise_management");

    const CsvResponse = () => {
        if(loggedInUserType != CONSTANTS.USER_TYPE_ADMIN || productManagementPermission?.permissions?.download == ROLE_MANAGEMENT_SYSTEM.PERMISSION_ALLOWED){
            handleLoader(true);
            const payload = {};
            const isHitDummyURL = false;
            new TemplateDownloadcsv(payload, isHitDummyURL)
                .dowloadCsv()
                .then((res) => {
                    downloadFile(res);  
                })
                .catch((err: any) =>{
                    handleLoader(false);
                    showSnackbar(err?.message, false);
                });
        }
       
    };

    const downloadFile = (res:any) =>{
        const payload = {};
        const isHitDummyURL = false;
        new TemplateDownloadcsv(payload, isHitDummyURL)
            .templateNameChanged(res)
            .then((response) => {              
                const blob = new Blob([response.data], {
                    type: response.headers["content-type"],
                });
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = `商品一覧_${storeCode}_テンプレート.xlsx`;
                link.click();
                handleLoader(false);
            })
            .catch((err: any) =>{
                handleLoader(false);
                showSnackbar(err?.message, false);
            });
    };

    return (
        <>
            <div className="main">
                <Container>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box sx={{ my: 4 }}>
                                <Typography>{t("profile.information_about_the_bulk")}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Container>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className={`${loggedInUserType==1 && productManagementPermission?.permissions?.download == ROLE_MANAGEMENT_SYSTEM.PERMISSION_NOTALLOWED ? "disableTemplateDownload" :""} Product-template-download-border cursor-pointer`}
                            onClick={CsvResponse}
                        >
                            <Box >
                                <CloudDownloadIcon className ="download-upload-icon" />
                                <Typography>{t("profile.template_download")}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    );
};
export default TemplateDownload;
