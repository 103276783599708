import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { Crypt } from "../../../utils/Crypt";
import ProductListApi from "../services/productList";
import { Grid, Typography } from "@mui/material";
import { ROUTES } from "../../../constants/routes";
import { useTranslation } from "react-i18next";
import { datadogLogs } from "@datadog/browser-logs";

const ProductFileDownload = (props:any) => {

    const { showSnackbar } = props;
    const { t, i18n } = useTranslation();

    const location = useLocation();
    const crypt = new Crypt();
    const navigate = useNavigate();

    const params = new URLSearchParams(location.search);
    const paramValue = params.get("download_token");
    const decodeUrl = decodeURI(paramValue? paramValue :"");
    const finalUrl =decodeUrl.replace(/\s/g, "+");
    const decryptedValue = crypt.decryptWithCryptoJS(finalUrl,process.env.REACT_APP_ENCRYPTION_IV);

    const checkProductDownloadResponseStatus = () => {
        const payload = {};
        const isHitDummyURL = false;
        new ProductListApi(payload, isHitDummyURL)
            .downloadProductResponseCheck(decryptedValue)
            .then((res) => {
                if(res){
                    const link = document.createElement("a");
                    link.href = decryptedValue  ;
                    link.click();
                    navigate(ROUTES.PRODUCT_LIST);
                }else{
                    navigate(ROUTES.ERROR_DOWNLOAD_PRODUCT);
                }
            })
            .catch((err: any) => {
                datadogLogs.logger.error(err);  
                showSnackbar(err?.message, false);
            });
    };

    useEffect(()=>{
        if(decryptedValue != ""){
            checkProductDownloadResponseStatus();
        }else{
            navigate(ROUTES.ERROR_DOWNLOAD_PRODUCT);
        }
    },[]);

    return (       
        <>
            <Grid container justifyContent={"center"} sx={{pt: 30}}>
                <Typography fontSize={50}>{t("verify_invitation.please_wait")}</Typography>
            </Grid>
        </>
    );
};

export default ProductFileDownload;
