import { useAppSelector } from "../redux/hooks";
import { newPermissionCategory } from "../constants/permissionCategory";
import CONSTANTS from "../constants/constants";
import { newPermissionInitialValue } from "../redux/features/newRoleBasedPermission/newPermissionSlice";

export const ProtectedMenu = ({ children, category }: any) => {
    
    const permissions = useAppSelector(state => state?.newPermission?.newPermissionDetails) ?? newPermissionInitialValue.newPermissionDetails;
    
    const { userType, modules } = permissions;

    if(userType == CONSTANTS.USER_TYPE_SUPER_ADMIN) {
        return children;
    }
    
    if (category != newPermissionCategory.SUPER_ADMIN || userType == CONSTANTS.USER_TYPE_ADMIN) {
        if (modules?.seller) {
            let count = 0;
            for (let i = 0; i < modules?.seller?.length; i++) {
                if (category == modules?.seller[i]?.key && modules?.seller[i]?.permissions?.view) {
                    count++;
                    return children;
                }
            }
            if (count == 0) {
                return null;

            }
        }
    }
};