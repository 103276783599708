import { Button, CircularProgress } from "@mui/material";
import React, { useEffect,  useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { ROUTES } from "../../constants/routes";
import { ROLE_MANAGEMENT_SYSTEM } from "../../constants/constants";
import { newPermissionCategory } from "../../constants/permissionCategory";
import { newPermissionInitialValue } from "../../redux/features/newRoleBasedPermission/newPermissionSlice";

const DownloadButton = (props:any) =>{
    
    const {loading, functionToExecute, route, className, btnName, disableCondition, endIconRequired, sx}=props;

    const loggedInUserType = useAppSelector(state => state.permission.permissionDetails.userType) ?? null;

    const permission_list = useAppSelector((state) => state.newPermission.newPermissionDetails.modules?.seller)  ?? newPermissionInitialValue.newPermissionDetails.modules?.seller;

    const [isPermissionallowed, setIsPermissionAllowed] = useState<boolean>(true);


    /**
 * @description -   setting isPermissionAllowed state to true if current route has download permission
 */
    useEffect(() => {
        
        const checkPermission = () => {         
            if (route == ROUTES.OMS || route.includes(ROUTES.OMS_CONFIRM_SHIPMENT) || route.includes(ROUTES.REFUND_ORDER)) {
                const omsPermission = permission_list?.find((item:any) => item.key == newPermissionCategory.ORDER_MANAGEMENT);
                setIsPermissionAllowed(omsPermission?.permissions?.download == ROLE_MANAGEMENT_SYSTEM.PERMISSION_NOTALLOWED ? false :true);
            } else if (route == ROUTES.BULK_IMAGE_UPLOAD || route == ROUTES.BULK_PRODUCT_UPLOAD || route == ROUTES.PRODUCT_LIST || route == ROUTES.IMAGE_UPLOAD) {
                const productManagementPermission = permission_list?.find((item:any) => item.key == newPermissionCategory.MERCHANDISE_MANAGEMENT);
                setIsPermissionAllowed(productManagementPermission?.permissions?.download == ROLE_MANAGEMENT_SYSTEM.PERMISSION_NOTALLOWED ? false :true);
            } else if (route == ROUTES.MANAGE_INVENTORY) {
                const userManagementPermission = permission_list?.find((item:any) => item.key == newPermissionCategory.INVENTORY_CONTROL_MANAGEMENT);
                setIsPermissionAllowed(userManagementPermission?.permissions?.download == ROLE_MANAGEMENT_SYSTEM.PERMISSION_NOTALLOWED ? false :true);
            }  else if (route == ROUTES.SALES_HISTORY || route == ROUTES.PAYMENT_REPORTS) {
                const userManagementPermission = permission_list?.find((item:any) => item.key == newPermissionCategory.SALES_RECEIPT_MANAGEMENT);
                setIsPermissionAllowed(userManagementPermission?.permissions?.download == ROLE_MANAGEMENT_SYSTEM.PERMISSION_NOTALLOWED ? false :true);
            } else {
                setIsPermissionAllowed(true);
            }
        };
    
        if(loggedInUserType == 1){
            checkPermission();
        }
    }, [route]);

    return(

        <>
            <Button
                className={className}
                variant="contained"
                disabled={!isPermissionallowed ? !isPermissionallowed : disableCondition}
                endIcon={endIconRequired ? endIconRequired : ""}
                sx={sx ? sx : {textTransform: "capitalize"}}
                onClick={functionToExecute}
            >
                {btnName}
                {loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                        }}
                    />
                )}
            </Button> 
        </>
    );
};
export default DownloadButton;
