import { useAppSelector } from "../redux/hooks";
import { permissionCategory } from "../constants/permissionCategory";
import CONSTANTS from "../constants/constants";
import { ROUTES } from "../constants/routes";
import { permissionInitialValue } from "../interfaces/permissionInterface";

export const ProtectedRoute = ({ children, category, route }: any) => {

    const permissions = useAppSelector(state => state.permission.permissionDetails) ?? permissionInitialValue.permissionDetails;

    const { userType, modules } = permissions;

    if(category == permissionCategory.COMMON) {
        return children;
    }

    if(userType == CONSTANTS.USER_TYPE_EXHIBITOR && category == permissionCategory.LISTING_USER && route == ROUTES.INVITE_PAGE) {
        return window.location.replace(ROUTES.UNAUTHORIZED_VISIT);
    }

    if(userType == CONSTANTS.USER_TYPE_SELLER && route == ROUTES.EXHIBITION_USER_DETAIL) {
        return window.location.replace(ROUTES.UNAUTHORIZED_VISIT);
    }

    if(userType == CONSTANTS.USER_TYPE_EXHIBITOR && route == ROUTES.EXHIBITION_USER_LIST) {
        return window.location.replace(ROUTES.UNAUTHORIZED_VISIT);
    }
    if(userType != CONSTANTS.USER_TYPE_SUPER_ADMIN && route == ROUTES.ACTIVITY_LOG) {
        return window.location.replace(ROUTES.UNAUTHORIZED_VISIT);
    }

    if(modules && category != permissionCategory.COMMON){
        let count = 0;
        for(let i = 0; i < modules.length; i++){
            if(category == modules[i].key && modules[i]?.routes?.view){
                count++;
                return children;
            }
        }
        if( count == 0 ){
            return window.location.replace(ROUTES.UNAUTHORIZED_VISIT);
        }
    }
};