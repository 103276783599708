import { useAppSelector } from "../redux/hooks";
import { newPermissionCategory } from "../constants/permissionCategory";
import CONSTANTS from "../constants/constants";
import { ROUTES } from "../constants/routes";
import { newPermissionInitialValue } from "../redux/features/newRoleBasedPermission/newPermissionSlice";

export const ProtectedRouteForAdmin = ({ children, category }: any) => {

    const permissions = useAppSelector(state => state.newPermission.newPermissionDetails) ?? newPermissionInitialValue.newPermissionDetails;

    const { userType, modules } = permissions;

    if(userType == CONSTANTS.USER_TYPE_SUPER_ADMIN){
        return children;
    }
    else if(category == newPermissionCategory.COMMON){
        return children;
    }
    else if(userType == CONSTANTS.USER_TYPE_ADMIN){
        if(modules?.seller){
            let count = 0;
            for(let i = 0; i < modules?.seller?.length; i++){
                if(category == modules?.seller[i].key && modules?.seller[i]?.permissions?.view || category == newPermissionCategory.COMMON){
                    count++;
                    return children;
                }
            }
            if( count == 0 ){
                return window.location.replace(ROUTES.UNAUTHORIZED_VISIT);
                
            }
        }
    } else {
        return window.location.replace(ROUTES.UNAUTHORIZED_VISIT);
    }
};